import { createAsyncThunk } from '@reduxjs/toolkit'

import questionsRequest from 'src/api/requests/questions'
import questionsActions from 'src/store/domain/automaticPlan/questions'
import menuActions from 'src/store/domain/automaticPlan/menus'
import { PATH as PATH_AUTOMATIC_PLAN } from 'src/pages/automaticPlan'
import history from 'src/libs/history'
import uiActions from 'src/store/ui/notification'

export interface QuestionRequestInterface {
  menu_id: string
}

export interface MenuRequestType {
  selected?: boolean
}

export const getMenus = createAsyncThunk<any, MenuRequestType>(
  'get_menus',
  async (params, { dispatch }) => {
    try {
      dispatch(menuActions.updateLoading(true))

      const res = await questionsRequest.getMenus(params)
      if (res.data) {
        dispatch(menuActions.updateEntity(res.data.data))
      }
      dispatch(menuActions.updateLoading(false))
    } catch (error) {
      dispatch(menuActions.updateLoading(false))
    }
  },
)

export const getQuestionsByMenuId = createAsyncThunk<
  any,
  QuestionRequestInterface
>('get_questions_by_menu_id', async ({ menu_id }, { dispatch }) => {
  try {
    dispatch(questionsActions.updateLoading(true))
    dispatch(questionsActions.updateEntity([]))
    const res = await questionsRequest.getQuestionsByMenuId(menu_id)
    if (res.data) {
      dispatch(questionsActions.updateEntity(res.data))
    }
    dispatch(questionsActions.updateLoading(false))
  } catch (error) {
    dispatch(questionsActions.updateLoading(false))
  }
})

export const getQuestions = createAsyncThunk<any, QuestionRequestInterface>(
  'get_questions',
  async ({ menu_id }, { dispatch }) => {
    try {
      dispatch(questionsActions.updateLoading(true))
      const res = await questionsRequest.getQuestions(menu_id)
      if (res.data) {
        dispatch(questionsActions.updateEntity(res.data))
      }
      dispatch(questionsActions.updateLoading(false))
    } catch (error) {
      dispatch(questionsActions.updateLoading(false))
    }
  },
)

export interface PostAnswer {
  answer: any
  question_id: number
  question_type: string
}
export interface PostAnswersInterView {
  answers: PostAnswer[]
  reservation_id: string
  user_treatment_id?: string | number
  menu_id?: string | number
}

export const answerInterView = createAsyncThunk<any, PostAnswersInterView>(
  'answer_interview',
  async (
    { answers, reservation_id, user_treatment_id, menu_id },
    { dispatch },
  ) => {
    try {
      dispatch(questionsActions.updateLoading(true))
      const res = await questionsRequest.answer({
        answers,
        reservation_id,
        user_treatment_id,
      })
      history.push(`${PATH_AUTOMATIC_PLAN.PRECAUTION}?menu_id=${menu_id}`)
      if (res.data) {
        dispatch(questionsActions.updateEntity(res.data))
      }
      dispatch(questionsActions.updateLoading(false))
    } catch (error: any) {
      const message = error?.response?.data.message
      dispatch(questionsActions.updateLoading(false))
      dispatch(
        uiActions.onNotification({
          type: 'error',
          message: message,
        }),
      )
    }
  },
)

export const draffAnswer = createAsyncThunk<any, PostAnswersInterView>(
  'draff_answer',
  async ({ answers, reservation_id }, { dispatch }) => {
    try {
      dispatch(questionsActions.updateDraffLoading(true))
      await questionsRequest.draffAnswer({
        answers,
        reservation_id,
      })
      dispatch(questionsActions.updateDraffLoading(false))
    } catch (error) {
      dispatch(questionsActions.updateDraffLoading(false))
    }
  },
)
