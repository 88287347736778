import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { STORAGE_KEY } from 'src/constants/app'
import history from 'src/libs/history'
import { PATH } from 'src/pages/video/TemporaryReserveConfirm'
import { PATH as PATH_AUTOMATIC_PLAN } from 'src/pages/automaticPlan'
import addressRequest from 'src/api/requests/address'
import { useAppSelector } from 'src/store'
import { useDispatch } from 'react-redux'
import uiActions from 'src/store/ui/notification'
import { PHONE_REGEX } from 'src/utils/regex'

export type FormValues = {
  date?: string
  time?: string | null
  phone?: string | null
  phoneos?: string | null
}

export const useSchedule = () => {
  const user = useAppSelector((state) => state.ui.app.authInfo)
  const dispatch = useDispatch()

  const defaultValues: FormValues = {
    date: '',
    time: '',
    phone: user?.phone || '',
    phoneos: user?.os_type || '',
  }

  const validationSchema = Yup.object().shape({
    date: Yup.string().required('日付を選択してください。'),
    time: Yup.string().required('時間を選択してください。').nullable(),
    phone: Yup.string()
      .nullable()
      .required('入力してください。')
      .length(11, '携帯番号は11桁で入力してください。')
      .matches(PHONE_REGEX, '電話番号は０から始まる必要があります。'),
    phoneos: Yup.string().required('種類を選択してください。　').nullable(),
  })

  const onSubmit: SubmitHandler<FormValues> = async (data, showPhone) => {
    localStorage.setItem(STORAGE_KEY.VIDEO_SCHEDULE, JSON.stringify(data))
    if (showPhone) {
      var dataUpdatePhone = {
        phone: data?.phone,
        os_type: data?.phoneos,
      }
      await addressRequest
        .updatePhone(dataUpdatePhone)
        .then(() => {
          if (!user?.otp_verified_at) {
            history.push(PATH_AUTOMATIC_PLAN.SMS_AUTH)
          } else {
            history.push(PATH)
          }
        })
        .catch((error) => {
          dispatch(
            uiActions.onNotification({
              type: 'error',
              message: error.response.data.errors?.phone
                ? error.response.data.errors?.phone[0]
                : error.response.data.errors?.os_type[0] ||
                  error.response.data.message,
            }),
          )
        })
    } else {
      history.push(PATH)
    }
  }

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}
