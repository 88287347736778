import React, { useRef, useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Carousel } from 'antd'

import LPHeader from 'src/components/layout/Header/LPHeader'
import { PATH as AUTH_PATH } from 'src/pages/auth'
import { Template } from './styled'
import LocalStorage from 'src/utils/LocalStorage'
import { useAppDispatch, useAppSelector } from 'src/store'
import { PATH as PATH_STATUS_ERROR } from 'src/pages/error/StatusError'

// img
import img1 from 'src/assets/images/flow/img_1.png'
import img2 from 'src/assets/images/flow/img_2.png'
import img3 from 'src/assets/images/flow/img_3.png'
import img4 from 'src/assets/images/flow/img_4.png'
import img5 from 'src/assets/images/flow/img_5.png'
import iconNext from 'src/assets/images/flow/next.svg'
import iconPre from 'src/assets/images/flow/pre.svg'
import { getClinicDetail } from 'src/useCase/clinic'
import ButtonCustom from 'src/components/form/Button'

const FlowContainer: React.FC = () => {
  let navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.ui.app.authInfo)
  const slider = useRef<any>(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  const data = [
    {
      image: img1,
      content: '個人情報の入力、<br /> 問診・注意事項を確認してください',
    },
    {
      image: img2,
      content: 'オンライン診療をして、<br />処方のご提案を受けてください',
    },
    {
      image: img3,
      content: '決済を完了して、<br />会員登録をしてください',
    },
    {
      image: img4,
      content:
        '本人確認書類を<br />アップロードしてください<br />(保険証・免許証・マイナンバーカード)',
    },
    {
      image: img5,
      content: 'ドクターによる最終確認をして<br />商品の発送をいたします',
    },
  ]

  const params = useParams()

  useEffect(() => {
    const clinicUuid: string | undefined = params.clinic_uuid
    if (!clinicUuid) {
      navigate(PATH_STATUS_ERROR)
      return
    }
    dispatch(getClinicDetail({ uuid: clinicUuid }))
    LocalStorage.setClinicUuid(params.clinic_uuid)
  }, [])

  const nextRegister = () => {
    const accessToken = LocalStorage.accessToken
    if (accessToken) {
      if (user?.cycleOrderPaid) {
        navigate(PATH_STATUS_ERROR)
      } else {
        navigate(AUTH_PATH.MEDICAL_SPECIALTY)
      }
    } else {
      navigate(AUTH_PATH.REGISTER)
    }
  }

  const onChange = (currentSlide: number) => {
    setCurrentSlide(currentSlide)
  }

  return (
    <Template>
      <LPHeader />
      <div className="flow">
        <h2 className="text-center fw-bold mb-0">BIOPORT+をはじめる方へ</h2>
        <h3 className="text-center mb-30">お薬の処方までの流れ</h3>
        <div className="position-relative">
          {currentSlide !== 0 && (
            <>
              <span
                onClick={() => slider.current.prev()}
                style={{
                  top: '105px',
                  zIndex: 1,
                  position: 'absolute',
                }}
              >
                <img src={iconPre} alt="" srcSet="" />
              </span>
            </>
          )}
          <Carousel
            ref={slider}
            infinite={false}
            dots={true}
            afterChange={onChange}
          >
            {data.map((item, index) => {
              return (
                <div key={index}>
                  <div className="d-flex justify-content-center">
                    <div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={item.image}
                          width="236px"
                          height="236px"
                          alt=""
                          srcSet=""
                        />
                      </div>
                      <div
                        className="text-center mt-50"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></div>
                    </div>
                  </div>
                </div>
              )
            })}
          </Carousel>
          {currentSlide !== 4 && (
            <>
              <span
                style={{
                  top: '105px',
                  zIndex: 1,
                  position: 'absolute',
                  right: 0,
                }}
                onClick={() => slider.current.next()}
              >
                <img src={iconNext} alt="" srcSet="" />
              </span>
            </>
          )}
        </div>
        <div className="flow-header">
          <ButtonCustom
            loading={false}
            className="btn-default btn-gurkha mt-5"
            name={'さっそく、はじめてみよう！'}
            onClick={() => nextRegister()}
            showIconRight
          />
        </div>

        <div className="d-flex justify-content-center">
          <Link
            className="text-decoration-underline cl-black mt-30"
            to={AUTH_PATH.SIGN_IN}
          >
            会員登録済みの方はこちら
          </Link>
        </div>
      </div>
    </Template>
  )
}

export default FlowContainer
