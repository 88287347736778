import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useNavigate } from 'react-router-dom'

const TermsContainer: React.FC = () => {
  const navigate = useNavigate()
  const termList = [
    {
      title: '第1条（適用）',
      content: [
        {
          numbered: '１.',
          string:
            '本規約は、本サービス提供条件および本サービスの利用に関するSheep Medical株式会社（以下、「当社」といいます。）とサービス利用者様との間の権利および義務関係を定めることを目的とし、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。',
        },
        {
          numbered: '２.',
          string:
            '本規約の内容と本規約外における本サービスの説明・ヘルプ及びＱ＆Ａ・投稿・ガイドライン等（以下、「規定」といいます。）との間で齟齬がある場合は規定が優先して適用されるものとします。ただし、規定において本規約が優先的に適用されることが明示的に記載されている場合は、この限りではありません。',
        },
        {
          numbered: '３.',
          string:
            'ユーザーは本サービスの利用に際し、本規約の定めに従うことを承諾したものとみなします。',
        },
        {
          numbered: '４.',
          string:
            'ユーザーは本サービスの利用に際し、オンライン診療を受診することに同意したものとみなします。',
        },
        {
          numbered: '５.',
          string:
            'ユーザーは本サービスの利用に際し、登録医師が処方箋を発行しないことがあることに同意したものとみなします。',
        },
        {
          numbered: '６.',
          string:
            '本サービスの利用は、成年者（年齢満18歳以上の方）に限ります。未成年者は、法定代理人の同意があっても、本サービスを利用できません。',
        },
      ],
    },
    {
      title: '第２条（定義）',
      content: [
        {
          numbered: '１.',
          string:
            '「本サービス」とは、当社が企画・運営するオンライン診療・処方支援サービス「BIOPORT+」（理由の如何を問わず、サービスの名称又は内容が変更された場合には、当該変更後のサービスをも含むものとします。）をいいます。',
        },
        {
          numbered: '２.',
          string:
            '「ユーザー」とは、本サービスを利用して登録医療機関による診療等を受ける個人をいいます。',
        },
        {
          numbered: '３.',
          string:
            '「本サイト」とは、当社が、本サービスをユーザーに提供するためのウェブサイトおよびアプリケーションをいいます。',
        },
        {
          numbered: '４.',
          string:
            '「本契約」とは、当社がユーザーに対し、本サービスを提供し、ユーザーが当社、登録医療機関に対し、本規約に基づき発生する本サービスの利用料を支払うことを約することをいいます。',
        },
        {
          numbered: '５.',
          string:
            '「登録医療機関」とは、本サイトに登録している医療機関やその他の法人であり、診療、医薬品の処方等に従事する機関をいいます。',
        },
        {
          numbered: '６.',
          string:
            '「登録医師」とは、登録医療機関に属し、かつ、本サイトに登録している医師であり、診療、医薬品の処方等に従事する者をいいます。',
        },
      ],
    },
    {
      title: '第３条（サービス）',
      content: [
        {
          string:
            '本サービスは、当社が企画・運営するオンライン診療・処方支援サービスに関するプラットフォームをユーザーに提供するものです。本サービスの詳細は、当社が本サイトにて別途定めるものとします。',
        },
      ],
    },
    {
      title: '第４条（ユーザー）',
      content: [
        {
          numbered: '１.',
          string:
            'ユーザーは自らの意思によって本サービスを利用するものとします。',
        },
        {
          numbered: '２.',
          string:
            'ユーザーは本サービスの利用にあたり、本サイトの定めるところに従いユーザー登録を行いユーザー登録の完了をもって、または、会員登録を行わずに本サイトの定めるところに従い必要な情報を入力し情報の入力完了をもって、本サービスの利用契約が成立するものとします。',
        },
        {
          numbered: '３.',
          string:
            'ユーザーは、前項により登録したアカウントにより、本サービスを利用することができます。また、ユーザー１人につき、１つのアカウントのみ作成することができます。',
        },
        {
          numbered: '４.',
          string:
            'ユーザーは、本サービスのアカウントID、パスワード等を適切に管理及び保管する責任を負い、アカウントID、パスワード等を第三者に利用させ、又は貸与、譲渡、売買等をしてはならないものとします。アカウントID、パスワード等の管理不十分、使用上の過誤、第三者の使用等に起因してユーザーに生じた損害については、当社は一切責任を負いません。',
        },
        {
          numbered: '５.',
          string:
            '当社は、ユーザーが以下に定める事由のいずれかに該当した場合、サービス提供を拒否または登録を抹消することができるものとします。なお、当社は、当該拒否、及び抹消について一切の責任を負わず、またその理由を説明する義務を負わないものとします。',
        },
        {
          numbered: '①',
          string:
            '本サービスにおいて、登録医師が、法令に準じ、かつ、必要に応じて「対面」での診療をユーザーに指示したにもかかわらず、「対面」での診療に同意しない、あるいは受診しない場合',
        },
        {
          numbered: '②',
          string:
            '本規約に違反する行為を行うおそれがある場合または過去に違反した事実が判明した場合 ',
        },
        {
          numbered: '③',
          string:
            '登録時に当社に虚偽の情報を提供し、または重要事項を提供しなかった場合',
        },
        {
          numbered: '④',
          string:
            'その他当社が、①の他、登録医師の指示に従わない等の規約違反、他のユーザーや登録医師等に対して問題がある言動等、本サービスをご利用になることが不適切と判断した場合',
        },
        {
          numbered: '６.',
          string:
            'ユーザーは本サービスにおいて自らが登録した情報の内容について一切の責任を負います。ユーザーが登録した情報に起因してユーザーに生じた損害については、当社は一切責任を負いません。',
        },
        {
          numbered: '７.',
          string:
            'ユーザーは本サービスにおいて登録した情報を、本サービスを利用するために必要な範囲内で、ユーザー自らが変更、追加、削除できるものとし、常にユーザーが責任をもって正確な状態に保つものとします。',
        },
        {
          numbered: '８.',
          string:
            'ユーザーは自らの意思により本サービスへの登録を削除し、退会することができます。ただし、ユーザーが退会した場合であっても、登録医療機関が当該ユーザーに関する登録情報その他本サービス上で作成、又は提供された情報等を保管する法的義務、又は必要性のある限りにおいて、当社はこれを継続保管、利用することができるものとします。',
        },
        {
          numbered: '９.',
          string:
            'ユーザーは自らの責任と費用において、本サービスの利用に必要な環境（ハードウェア、ソフトウェア、インターネット接続回線、セキュリティーの確保等）を整備するものとします。',
        },
      ],
    },
    {
      title: '第５条（診療・処方薬の配送等）',
      content: [
        {
          numbered: '１.',
          string:
            '本サービスにおける「診療」とは、登録医師が、本サイト上のチャットルーム（以下、「診察室」といいます。）を通じ、ユーザーに対し、診察、ユーザーの健康状態に応じた医薬品の処方、助言をすることをいいます。',
        },
        {
          numbered: '２.',
          string:
            'ユーザーは、本サービスを利用して診療を受けるにあたり、本サイトの定め、又は診療を担当する登録医師の指示により、診療を担当する登録医師に対し、ユーザーの健康状態、症状、体質その他の診療に関する情報を提供する義務を負います。ユーザーが十分な情報提供を行わなかったことにより、登録医師が適切な診療ができなかった場合、当社はそのことについて一切責任を負いません。',
        },
        {
          numbered: '３.',
          string:
            'ユーザーは、本サービスへの登録を完了後、本サイトを通じて、登録医師により診療を受けることができます。',
        },
        {
          numbered: '４.',
          string:
            'ユーザーは、本サービスを利用してオンライン診療を受診するとき、ユーザーのプライバシーを保つため、ユーザーが物理的に外部から隔離される空間において受診しなければならないものとします。',
        },
        {
          numbered: '５.',
          string:
            '登録医師は、都合により、診療を中止する場合があります。この場合、ユーザーは、別途、診療を担当する登録医師を選択することができます。なお、登録医師による診療の中止について、当社は一切責任を負いません。',
        },
        {
          numbered: '６.',
          string:
            'ユーザーは、あらかじめ対面での診察を受けていない場合、本サイトを通じたオンラインによる診察を受けられない場合があることに同意するものとします。',
        },
        {
          numbered: '７.',
          string:
            'ユーザーがオンライン診察後、登録医師が求めた場合、または、副作用その他異常を感じた場合は速やかに医療機関を受診することを推奨いたします。',
        },
        {
          numbered: '８.',
          string:
            'ユーザーに対する診療、医薬品の処方はすべて診療を担当した登録医師またはその所属する登録医療機関が行い、当社は、これらの行為についていかなる責任も負わないものとします。',
        },
        {
          numbered: '９.',
          string:
            'ユーザーと登録医師又は登録医療機関との間の診療に関する契約に関連して生じた問い合わせ、苦情、請求、紛争等については、ユーザー、登録医療機関、及び登録医療機関に所属する登録医師との間で解決するものとします。当社は、これらの紛争等については、一切の法的義務を負わないものとします。',
        },
        {
          numbered: '10.',
          string:
            'ユーザーは、本サービスの利用により、ユーザーの指示に基づき、登録医療機関からユーザーに対して医薬品の配送が行われることがあること、及びかかる配送は本サービスにユーザーが登録した住所等の情報に基づき行われることにつき同意するものとします。当社は、当社のシステムの不具合に起因する場合除き、医薬品の配送について一切責任を負いません。',
        },
      ],
    },
    {
      title: '第６条（利用料金）',
      content: [
        {
          numbered: '１.',
          string:
            '本サービスに係る利用料金は、登録医師、又は登録医療機関が診察室上でユーザーに提示した料金（以下、「本サービス利用料」といいます。）であり、ユーザーは、本サービス利用料を、当社に受領させる方法で支払うものとします。 ',
        },
        {
          numbered: '２.',
          string:
            '本サービス利用料は、本サービスを通じて登録医療機関より提供される診療の代金、医薬品購入代金、配送料、当社のシステム利用料等の合計額となります。本サービス利用料には、診療報酬、医薬品購入代金等当社が登録医療機関を代理して受領するものが含まれますが、当社が診療等や医薬品の販売を行っているものではありません。',
        },
        {
          numbered: '３.',
          string:
            '当社は、ユーザーから医療機関への紹介料や仲介費用はいただいておりません。',
        },
        {
          numbered: '４.',
          string:
            '本サービス利用料のうち、事前に確定できるものについては本サイト上に原則として明示します。ただし、ユーザーは、ユーザーの健康状況等によって診療内容や費用が異なるため、診療前に金額を完全に確定することができないことにつき同意するものとします。',
        },
        {
          numbered: '５.',
          string:
            '本サービスにおいては、原則として、登録医療機関が医薬品の指定を行い、ユーザーによる医薬品の指定はできません。また、本サイト上に表示がされていない医薬品については、登録医療機関等の判断にかかわらず、本サービスで取り扱うことはできません。',
        },
        {
          numbered: '６.',
          string:
            '通常の医療機関の受診と同様に診察・処方・調剤が完了しますので、処方確定後の医薬品の返品・交換・キャンセルは出来ません。',
        },
        {
          numbered: '７.',
          string:
            '本サービス利用料の支払いは、ユーザーが本サイトの利用にあたり登録したクレジットカード決済によってなされるものとします。ユーザーは、クレジットカードの登録情報に変更または誤りがあった場合、直ちに修正するものとします。ユーザーのクレジットカードによる決済が1回でもできなかった場合、当社は、ユーザーによる本サービスの利用を停止させることができるものとします。',
        },
      ],
    },
    {
      title: '第７条（禁止事項・損害賠償）',
      content: [
        {
          numbered: '１.',
          string: 'ユーザーは、以下の各行為を行ってはならないものとします。',
        },
        {
          numbered: '①',
          string:
            '当社、登録医療機関及び登録医師に対し、虚偽の情報を提供する行為',
        },
        {
          numbered: '②',
          string:
            '他人の診療を受ける目的で、他人に薬剤を譲渡する目的で、本サービスを利用する行為',
        },
        {
          numbered: '③',
          string:
            '本サービスの利用に関し、自らまたは第三者のために不正な利益を得ようとする行為',
        },
        {
          numbered: '④',
          string: '登録医師の承諾なしにビデオ通話を録音、録画、撮影する行為',
        },
        {
          numbered: '⑤',
          string:
            '他人の知的財産権、プライバシーに関する権利、その他の権利または利益を侵害する行為',
        },
        {
          numbered: '⑥',
          string:
            'コンピューター・ウイルスその他の有害なコンピューター・プログラムを含む情報を送信する行為',
        },
        { numbered: '⑦', string: '個人や団体を誹謗中傷する行為' },
        {
          numbered: '⑧',
          string:
            '本サービスで得た情報を本サービスの利用目的の範囲を超えて第三者に譲渡する行為、または営利目的で譲渡する行為',
        },
        { numbered: '⑨', string: '公序良俗に反する行為' },
        { numbered: '⑩', string: '法令に反する一切の行為' },
        {
          numbered: '⑪',
          string:
            '各登録医療機関への予約、及びキャンセルを繰り返す等、各登録医療機関の診療を妨害する方法で本サービスを利用する行為',
        },
        { numbered: '⑫', string: '本サービスの運営を妨げる行為' },
        {
          numbered: '⑬',
          string:
            'その他本サービスの提供を継続することが困難であると当社が判断する一切の行為',
        },
        {
          numbered: '２.',
          string:
            'ユーザーが本規約の各条項に違反し、当社に対して損害を与えた場合は、ユーザーは当社に対し損害賠償義務を負うものとします。',
        },
      ],
    },
    {
      title: '第８条（情報の変更・削除、本契約の解除等）',
      content: [
        {
          numbered: '１.',
          string:
            '当社は、ユーザーが本規約に違反する行為をし、もしくはその行為をする恐れがある場合、ユーザーによる本サービスの利用が不適切であると当社が判断する場合、ユーザーによる本サービスの利用により本サービスの運営に支障が生じると当社が判断する場合、当該ユーザーに何ら事前の通知をすることなく以下の措置を講じることができるものとします。なお、当社はユーザーに対し、下記の措置を講じる理由について説明する義務を負いません。',
        },
        {
          numbered: '①',
          string:
            'ユーザーが本サービスにおいて登録した情報の全部または一部についての変更または削除',
        },
        {
          numbered: '②',
          string:
            '本契約の解除およびそれに伴う本サービス利用の停止、または本サービスのユーザーとしての登録の抹消',
        },
        { numbered: '③', string: 'その他当社が必要と認める措置' },
      ],
    },
    {
      title: '第９条（サービス内容の変更、停止・終了等）',
      content: [
        {
          numbered: '１.',
          string:
            '当社は、ユーザーの事前の通知、及び承諾を要することなく、本サービスの内容を変更することができるものとします。',
        },
        {
          numbered: '２.',
          string:
            '当社は、以下のいずれかに該当する事由によりユーザーへの事前の通知および承諾を要することなく、本サービスを停止または終了することができます。',
        },
        {
          numbered: '①',
          string:
            '本サービス運営のためのシステムの保守、更新等を定期的または臨時に行う場合',
        },
        {
          numbered: '②',
          string:
            'ウィルス被害、火災、停電、天災地変などの不可抗力により、本サービスの提供が困難となった場合',
        },
        {
          numbered: '③',
          string:
            '第三者の故意または過失による行為によって発生した本システムの不具合について対策を講じる必要がある場合',
        },
        {
          numbered: '④',
          string: '法令等の改正、成立により本サービスの運営が困難となった場合',
        },
        {
          numbered: '⑤',
          string:
            'その他、当社が本サービスの提供の停止・終了が必要と判断した場合',
        },
      ],
    },
    {
      title: '第１０条（免責）',
      content: [
        {
          numbered: '１.',
          string:
            '当社は、ユーザーが本サービスに登録し掲載する情報等に関し、内容の正確性、有用性、完全性等について何らの保証をしないものとします。',
        },
        {
          numbered: '２.',
          string:
            '当社は、本サービスの利用に関し、以下のことを保証しないものとします。',
        },
        { numbered: '①', string: 'サービスが中断しないこと' },
        { numbered: '②', string: '本サービスにエラーが生じないこと' },
        {
          numbered: '③',
          string: '本サービスの利用に関し通信回線等の障害がないこと',
        },
        {
          numbered: '④',
          string:
            '本サイト上のコンテンツに関する盗用、毀損または改ざんがないこと',
        },
        {
          numbered: '⑤',
          string:
            '本サイトに対する不正アクセス・ハッキング等のサイバー攻撃がないこと',
        },
        { numbered: '⑥', string: 'その他通信設備の不具合が生じないこと' },
        { numbered: '⑦', string: '医薬品発送後の責任（配達事故や遅延）' },
        {
          numbered: '３.',
          string:
            '当社は、当社によるユーザー情報の変更、削除または消失、本サービスの内容の変更、本サービスの提供の停止または終了、本サービスの利用不能、本サービスの利用によるデータの消失または機器の故障若しくは損傷、その他本サービスに関連してユーザーが被った損害につき、一切責任を負わないものとします。',
        },
        {
          numbered: '４.',
          string:
            '当社が本サービスに関してユーザーに対して損害賠償責任を負う場合であっても、ユーザーは当社に損害発生についての故意または重過失がある場合に限り、直接生ずべき損害の範囲内において、損害賠償を請求することができるものとします。ただし、この場合においても、当社がユーザーに対して賠償する損害の累計額は、当社が本サービスに関連して当該ユーザーから受領した本サービス利用料の合計額を上限とします。',
        },
        {
          numbered: '５.',
          string:
            '第８条の規定により、ユーザーの情報が変更または削除された場合、本契約が解除された場合、本サービス内容が変更された場合、または本サービスが停止・終了した場合においても、ユーザーと登録医師またはその所属する登録医療機関との間で成立する診療契約の内容に影響を及ぼすものではありません。',
        },
        {
          numbered: '６.',
          string:
            '当社は、ユーザーによる本サービスの利用により、ユーザーの疾病または疾患が治癒し、診療が終了することを保証するものではなく、また、登録医師またはその所属する登録医療機関がユーザーの診療を開始・継続することを保証するものではありません。',
        },
        {
          numbered: '７.',
          string:
            'ユーザーは診察時間内であれば、登録医療機関で直接診療を受けることができます。なお、体調がすぐれない、身体に異常がみられる等の場合は、すぐに最寄りの医療機関を受診するようにしてください。',
        },
      ],
    },
    {
      title: '第１１条（暴力団等排除条項）',
      content: [
        {
          numbered: '１.',
          string:
            'ユーザーは現在、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない 者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力団等、その他これらに準ずる 者(以下、これらを「暴力団員等」といいます。)に該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。',
        },
        {
          numbered: '２.',
          string:
            'ユーザーは自らまたは第三者を利用して次の各号の一にでも該当する行為を行わないことを確約するものとします。',
        },
        { numbered: '①', string: '暴力的な要求行為' },
        { numbered: '②', string: '法的な責任を超えた不当な要求行為' },
        {
          numbered: '③',
          string: '取引に関して、脅迫的な言動をし、または暴力を用いる行為',
        },
        {
          numbered: '④',
          string:
            '風説を流布し、偽計を用いまたは威力を用いて、当社、他の利用者、その他第三者の信用を毀損し、または当社、他の利用者、その他第三者の業務を妨害する行為',
        },
        { numbered: '⑤', string: 'その他前各号に準ずる行為' },
      ],
    },
    {
      title: '第１２条（提供情報の利用）',
      content: [
        {
          numbered: '１.',
          string:
            '本サービスにおける個人情報の取り扱いについては、当社の定める「プライバシーポリシー」に従うものとします。ユーザーは、本サービスを利用する場合には、「プライバシーポリシー」の各規定に従うことを承諾したものとみなします。',
        },
        {
          numbered: '２.',
          string:
            '当社は、本サービスを提供する上でユーザーにとって必要な情報を、ユーザーに対し、Eメール、郵便、電話、対面、本サイト内のチャット等での伝達等によって連絡をすることができるものとします。',
        },
        {
          numbered: '３.',
          string:
            '当社は、当社の定める「プライバシーポリシー」で定義される個人情報を含まない限りにおいて、登録情報または本サービスの利用状況についての情報を、あらゆるものに二次利用することができるものとします。これらの情報に関わる知的財産権は当社が保有するものとします。',
        },
        {
          numbered: '４.',
          string:
            'ユーザーは、ユーザーが本サイトを通じて登録医療機関からサービスの提供を受けるためにユーザーが入力した情報及びその他診察等に関連した情報について、登録医療機関に共有されることについて同意したものとします。',
        },
      ],
    },
    {
      title: '第１３条（規約の変更）',
      content: [
        {
          numbered: '１.',
          string:
            '当社は、ユーザーの承諾を得ることなく、本規約を随時変更することができ、変更は末尾記載の改定日より効力を生じるものとします。',
        },
        {
          numbered: '２.',
          string:
            '当社は、本規約を変更したときは、速やかに、本サイト上に変更後の本規約を掲載します。',
        },
      ],
    },
    {
      title: '第１４条（地位譲渡）',
      content: [
        {
          numbered: '１.',
          string:
            'ユーザーは、当社の書面による事前の承諾なく、本規約に基づく権利または義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。',
        },
        {
          numbered: '２.',
          string:
            '当社が本サービスにかかる事業を第三者に譲渡する場合には、ユーザーの承諾を得ることなく、当該事業譲渡に伴い、本規約に基づく権利および義務並びにユーザーの登録情報その他の顧客情報等を含む本契約上の地位を当該事業譲渡の譲受人に譲渡することができるものとします。なお、このことは、事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合においても同様とします。',
        },
      ],
    },
    {
      title: '第１５条（準拠法及び管轄裁判所）',
      content: [
        {
          string:
            '本サービスおよび本規約を含む本契約の準拠法は日本法とします。本サービスおよび本規約を含む本契約に関して生じる一切の紛争については、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。',
        },
      ],
    },
    {
      title: '第１６条（分離可能性）',
      content: [
        {
          string:
            '本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの条項、及び一部が無効又は執行不能と判断された条項の残りの部分は、継続して完全に効力を有するものとします。',
        },
      ],
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const back = () => {
    navigate(-1)
  }

  const termComponents = termList.map((term, index) => (
    <div key={index}>
      {/* title */}
      <h3 className="term-header">{term.title}</h3>

      {/* content */}
      <>
        {term.content.map((content, index) => (
          <div className="term-content" key={index}>
            <p>{content.numbered}</p>
            <p>{content.string}</p>
          </div>
        ))}
      </>

      {/* footer */}
      {index + 1 < termList.length ? (
        <hr className="policy-hr" />
      ) : (
        <p className="mt-20">2023年3月10日制定</p>
      )}
    </div>
  ))
  return (
    <Template>
      <div className="policy">
        <Wrapper>
          <h2 className="policy-top">利用規約</h2>
          <div className="policy-content">{termComponents}</div>
        </Wrapper>
        <div className="policy-footer" onClick={back}>
          <span className="mr-10">＜</span>戻る
        </div>
      </div>
    </Template>
  )
}

const Wrapper = styled.div`
  padding: 30px 20px 20px 20px;
  .term {
    &-header {
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
    }

    &-content {
      display: flex;
      & p {
        font-size: 13px;
        line-height: 24px;
        margin: 0;
      }
    }
  }
`

const Template = styled.div``

export default TermsContainer
