import React from 'react'
import styled from 'styled-components'

import LPHeader from 'src/components/layout/Header/LPHeader'
import ReservationInfo from 'src/components/common/ReservationInfo'
import ButtonCustom from 'src/components/form/Button'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'src/pages/myPage/OtherMenu'
import HeaderUnderlined from 'src/components/layout/Header/HeaderUnderlined'

const Container: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview mt-15">
        <HeaderUnderlined name="診察のご案内" />
        <div className="page-title fs-20 fw-500 mt-30">
          オンライン診察予約完了
        </div>
        <ReservationInfo />
        <div className="confirm-success__content">
          <p className="margin-b-15px">予約が完了しました。</p>
          <span>
            ご予約した当日にオンライン診察のリンクをメールにてご案内致します。
          </span>
          <br />
          <span>オンライン診察当日にメールをご確認お願い致します。</span>
        </div>
        <ButtonCustom
          className="btn-default my--30 btn-gurkha"
          loading={false}
          onClick={() => navigate(PATH)}
          name="マイページ"
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .confirm-success {
    &__content {
      margin: 20px 0;
      font-size: 14px;
      background-color: #f1efe7;
      padding: 20px;
    }
  }
`

export default Container
