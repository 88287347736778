import React, { useEffect } from 'react'

import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import ButtonCustom from 'src/components/form/Button'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PATH as PATH_MY_PAGE } from 'src/pages/myPage'
import DescriptionHint from 'src/components/layout/Content/DescriptionHint'
import { useAppDispatch, useAppSelector } from 'src/store'
import LodashGet from 'lodash/get'
import { getDetailUserTreatment } from 'src/useCase/userTreatment'
import { getFirstDoctor } from 'src/useCase/doctor'
import LocalStorage from 'src/utils/LocalStorage'

const SuggestSubscriptionContainer: React.FC = (props: any) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [queryParams] = useSearchParams()

  const next = () => {
    navigate(PATH_MY_PAGE.OTHER_MENU)
  }

  const menuName = LocalStorage.getMenuName()

  const userTreatment = useAppSelector(
    (state) => state.domain.myPage.detailUserTreatment.entity,
  )

  useEffect(() => {
    const id =
      LocalStorage.getUserTreatmentId() ?? queryParams.get('userTreatmentId')
    const params = {
      with: 'lastDoctorFeedback.doctor',
    }
    dispatch(getDetailUserTreatment({ id, params }))
  }, [])

  useEffect(() => {
    if (
      userTreatment &&
      userTreatment.id &&
      !userTreatment?.last_doctor_feedback
    ) {
      dispatch(getFirstDoctor())
    }
  }, [userTreatment])

  const firstDoctor = useAppSelector(
    (state) => state.domain.doctor.firstDoctor.entity,
  )

  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form">
        <StepsHeader
          step={10}
          stepActive={3}
          stepActiveName={'処方のご提案'}
          className="top-70"
        />
        <HeaderStyle>問診回答の結果、お薬の処方ができません。</HeaderStyle>

        <DescriptionHint
          listText={[
            `${LodashGet(
              userTreatment,
              'last_doctor_feedback.doctor.name',
              firstDoctor?.name,
            )}です。`,
            '<br />',
            ' 問診のご協力ありがとうございました。 ',
            `大変申し訳ありませんが、診察の結果「診療科目：${menuName}」におけるプランについてはお薬の処方ができません。`,
            'マイページより別の診療科目のプランをご検討ください。',
            '本件につきまして、ご質問やご要望がございましたら、以下のアドレス宛にご連絡ください。',
            'bioportplus@sheepmedical.com',
          ]}
        />
        <ButtonCustom
          className="btn-default btn-gurkha mt-5"
          loading={false}
          onClick={next}
          htmlType="submit"
          name="マイページ"
          showIconRight
        />
      </div>
    </>
  )
}

export default SuggestSubscriptionContainer
