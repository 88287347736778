import HTTPRequest from '../index'

const apiPath = '/api/v2/users/user-treatments'
import { FormValues } from 'src/containers/MedicalSpecialty/components/useMedicalSpecialtyForm'

class UserTreatment extends HTTPRequest {
  index(params) {
    return this.get(`${apiPath}`, { params })
  }

  create(value: FormValues) {
    return this.post(`${apiPath}`, { ...value })
  }

  show(id, params) {
    return this.get(`${apiPath}/${id}`, { params })
  }

  update(id, data) {
    return this.patch(`${apiPath}/${id}`, data)
  }

  cancel(id) {
    return this.delete(`${apiPath}/${id}`)
  }

  updateCallState(id, state) {
    return this.put(`${apiPath}/${id}/update-call-state`, { state })
  }
}

export default new UserTreatment()
