import React from 'react'
import Modal from './BasicModalConfirm'

import agaPopupIcon from 'src/assets/icons/sheep.jpeg'
import { TopIcon } from './ModalStyle'

type Props = {
  isOpen: boolean
  onSubmit: () => void
  onCancel: (submit: boolean) => void
  deliveryDate: string
}

const SkipDeliveryModal: React.FC<Props> = (props) => {
  const handleCancel = () => {
    props.onCancel(false)
  }

  const handleSubmit = () => {
    props.onSubmit()
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={handleCancel}
      onSubmit={handleSubmit}
      labelSubmit="スキップする"
    >
      <div className="d-flex justify-content-center">
        <TopIcon>
          <img className="img" src={agaPopupIcon} alt="" />
        </TopIcon>
      </div>
      <div className="modal-content styleCustomModal">
        <div className="modal-body">
          <p>{props.deliveryDate}配送</p>
          <p>こちらの配送をスキップしますか？</p>
        </div>
      </div>
    </Modal>
  )
}

export default SkipDeliveryModal
