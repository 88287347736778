import React, { useEffect, useState } from 'react'
import { filter, includes } from 'lodash'
import { Carousel, Row } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import qs from 'query-string'

import LPHeader from 'src/components/layout/Header/LPHeader'
import ShampooHeader from 'src/components/layout/Header/ShampooHeader'
import ButtonCustom from 'src/components/form/Button'
import { useAppDispatch, useAppSelector } from 'src/store'
import { getPlanDetail } from 'src/useCase/plan'
import { formatNumPrice, getUserPlanType } from 'src/utils/helper'
import { MedicineInterface } from 'src/interfaces/plan'
import { WrapperPlan } from './styled'
import { MAP_KEYS } from './constants'

import iconOpen from 'src/static/img/icon-open.svg'
import iconClose from 'src/static/img/icon-close.svg'
import { getInfo } from 'src/useCase/user'
import LocalStorage from 'src/utils/LocalStorage'
import { PATH as PAYMENT_PATH } from 'src/pages/payment'
import { PLAN_TYPE } from 'src/constants/app'
import { PLAN_PAYMENT_TYPE } from 'src/constants/plan'
import LazyLoadImage from 'src/components/common/LazyLoadImage'
import { updateUserTreatment } from 'src/useCase/userTreatment'

const PlanDetailContainer: React.FC = (props: any) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const paramsUrl = useParams()
  const planDetail = useAppSelector(
    (state) => state.domain.plans.planDetail.entity,
  )
  const isLoading = useAppSelector((state) => state.domain.plans.plans.loading)
  const user = useAppSelector((state) => state.ui.app.authInfo)
  const planType =
    LocalStorage.planType || getUserPlanType(user?.state_transitions || [])
  const [openMedicines, setOpenMedicines] = useState([] as string[])
  const paramsSearch = qs.parse(location.search)

  useEffect(() => {
    dispatch(
      getPlanDetail({
        plan_id: Number(paramsUrl?.id),
        with: 'medicines',
      }),
    )
  }, [])

  const back = () => {
    navigate(-1)
  }

  useEffect(() => {
    dispatch(
      getInfo({
        with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay,userTreatments,userTreatments.userTreatmentTransitions',
      }),
    )
  }, [])

  const confirmData = () => {
    if (planDetail) {
      const id = Number(LocalStorage.getUserTreatmentId())

      dispatch(
        updateUserTreatment({
          id,
          data: { state: 'not_paid', plan_id: planDetail.id },
        }),
      )
      navigate(
        `${PAYMENT_PATH.PAYMENT_INFO}?doctor_feedback_id=${paramsSearch.feedbackId}`,
      )
    }
  }

  const setMedicineActive = (medicine: MedicineInterface, index: number) => {
    let newListMedicines = [...openMedicines]
    if (includes(newListMedicines, `medicine-${medicine.id}-${index}`)) {
      newListMedicines = filter(
        newListMedicines,
        (data) => data !== `medicine-${medicine.id}-${index}`,
      )
    } else {
      newListMedicines = [
        ...newListMedicines,
        `medicine-${medicine.id}-${index}`,
      ]
    }
    setOpenMedicines(newListMedicines)
  }

  const srcShowIconMedicines = (medicine: MedicineInterface, index: number) => {
    if (includes(openMedicines, `medicine-${medicine.id}-${index}`)) {
      return iconClose
    }
    return iconOpen
  }

  const getHeight = (value: any) => {
    if (value) {
      const dataBreakLine = Object.assign(value)
      let dataBreak = dataBreakLine.split(/\r\n|\n/).length
      const dataLi = Object.assign(value)
      let dataLiLine = dataLi.split(/・/).length

      if (dataLiLine > 3) {
        return `${value.length + dataLiLine * 18}px`
      }
      if (dataLiLine > 2) {
        return `${value.length + dataLiLine * 10}px`
      }
      if (dataBreak > 2) {
        return `${value.length + dataBreak * 20}px`
      }
      return `${value.length + 20}px`
    } else {
      return '40px'
    }
  }

  const isHasMedicines = (medicine: MedicineInterface, index: number) => {
    return includes(openMedicines, `medicine-${medicine.id}-${index}`)
  }

  const isSubscription = planType === PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION
  const isExam = planType === PLAN_TYPE.EXAMINATION
  const headerTitle = isExam
    ? 'ご提案プランのお薬説明'
    : isSubscription
    ? '定期購入プランの商品説明'
    : 'ご提案プランのお薬説明'

  return (
    <>
      <LPHeader />
      <ShampooHeader name={headerTitle} noneBorder />
      <WrapperPlan>
        <div className="plan-detail">
          <div className="plan-detail__header">
            {planDetail && planDetail.name}
          </div>
          {planDetail && (
            <>
              {planDetail?.medicines && (
                <Carousel autoplay>
                  {planDetail?.medicines.map((medicine, index) => {
                    return (
                      <div className="position-relative  mb-20" key={index}>
                        <div className="plan-detail-absolute" />
                        {/* <img
                          src={medicine.image_url}
                          alt=""
                          className="plan-detail-img mb-10"
                        /> */}
                        <LazyLoadImage
                          className="plan-detail-img mb-10"
                          imgSrc={medicine.image_url}
                        />
                      </div>
                    )
                  })}
                </Carousel>
              )}
            </>
          )}
          <div className="plan-detail__submit">
            <div className="plan-detail__submit-money fs-14">
              セット
              <span className="plan-detail__submit-price ml-5">
                {planDetail?.price_after
                  ? formatNumPrice(planDetail?.price_after)
                  : 0}
              </span>
              円(税込) /
              {planDetail?.payment_type === PLAN_PAYMENT_TYPE.ONCE ? (
                <span>回</span>
              ) : (
                <span>{planDetail?.cycle} 日</span>
              )}
            </div>
            <div className="text-left mt-5 mb-5 fs-14">
              {!isSubscription && (
                <p>定期便は定期の期間が終了した後に再問診が必要となります。</p>
              )}
              <p>
                お体に合わない等、ご不明な点がございましたらチャットにてカスタマーサポートへご相談ください。
              </p>
            </div>

            <ButtonCustom
              className="btn-default btn-chestnut-rose my--30"
              loading={isLoading}
              disabled={false}
              onClick={confirmData}
              name="決済へ進む"
              showIconRight
            />
            <ButtonCustom
              className="btn-default btn-white my--30"
              loading={false}
              disabled={false}
              onClick={back}
              name="プラン選択へ戻る"
            />
          </div>
          {planDetail &&
            planDetail.medicines &&
            planDetail.medicines.length > 0 &&
            planDetail.medicines.map((medicine: MedicineInterface, index) => {
              return (
                <div className="plan-detail__medicines" key={medicine.id}>
                  <div className="plan-detail__medicines-top">
                    {medicine.name}
                  </div>
                  {/* <div className="plan-detail__image">
                    <img src={medicine.logo_image_url} alt="" />
                  </div> */}
                  <div className="plan-detail__medicines-header">
                    {/* <img
                      src={medicine.image_url}
                      alt=""
                      className="plan-detail__medicines-header-img"
                    /> */}
                    <LazyLoadImage
                      className="plan-detail__medicines-header-img"
                      imgSrc={medicine.image_url}
                    />
                    <div className="plan-detail__medicines-des">
                      <p className="plan-detail__medicines-item">用法・容量</p>
                      <p className="plan-detail__medicines-item-0 w-80 text-right">
                        {medicine.usage}
                      </p>
                    </div>
                    {Object.entries(MAP_KEYS).map((key, value) => {
                      return (
                        <div key={value} className="plan-detail__medicines-div">
                          <div
                            className="plan-detail__medicines-des"
                            onClick={() =>
                              setMedicineActive(medicine, value + 1)
                            }
                            id={`medicine-${medicine.id}-${value + 1}`}
                          >
                            <p className="plan-detail__medicines-item">
                              {key[1]}
                            </p>
                            <img
                              src={srcShowIconMedicines(medicine, value + 1)}
                              alt=""
                              className={`img-icon ${
                                isHasMedicines(medicine, value + 1)
                                  ? 'img-icon-open'
                                  : ''
                              }`}
                            />
                          </div>
                          {/* {medicine[key[0]] && medicine[key[0]].length > 0 && ( */}
                          <Row>
                            <textarea
                              className={`w-100 plan-detail__medicines-item-2 ${
                                !isHasMedicines(medicine, value + 1) ||
                                (medicine[key[0]] &&
                                  medicine[key[0]].length === 0)
                                  ? 'd-none-height'
                                  : ''
                              } ${
                                isHasMedicines(medicine, value + 1)
                                  ? 'd-block'
                                  : ''
                              }
                          `}
                              style={{ height: getHeight(medicine[key[0]]) }}
                              defaultValue={medicine[key[0]]}
                            ></textarea>
                          </Row>
                          {/* )} */}
                        </div>
                      )
                    })}
                  </div>
                  {/* <div
                    className={`detail-hr ${
                      planDetail?.medicines &&
                      index === planDetail?.medicines.length - 1
                        ? 'detail-hr-mt'
                        : ''
                    } border-bottom-ccc`}
                  /> */}
                </div>
              )
            })}
          <div className="text-center mb-12 mx--20">
            <ButtonCustom
              className="btn-default btn-chestnut-rose my--30"
              loading={isLoading}
              disabled={false}
              onClick={confirmData}
              name="決済へ進む"
              showIconRight
            />
            <ButtonCustom
              className="btn-default btn-white my--30"
              loading={false}
              disabled={false}
              onClick={back}
              name="プラン選択へ戻る"
            />
          </div>
        </div>
      </WrapperPlan>
    </>
  )
}

export default PlanDetailContainer
