import _ from 'lodash'
import moment from 'moment'
import Resizer from 'react-image-file-resizer'

import { USER_STATE } from '../enum/userStatus'
import { PATH as PATH_REGULAR_SHAMPOO } from 'src/pages/regular'
import { PATH as PATH_AUTOMATIC_PLAN } from 'src/pages/automaticPlan'
import { PATH as PATH_CHAT } from 'src/pages/chat/Chat'
import { PATH as PATH_VIDEO } from 'src/pages/video'
import { PATH as PATH_PAYMENT } from 'src/pages/payment'
import { PATH as PATH_MY_PAGE } from 'src/pages/myPage'
import { PLAN_TYPE } from 'src/constants/app'
import { PATH as PATH_ERROR } from 'src/pages/error'
import { PATH as PATH_KARTE_PLAN } from 'src/pages/kartePlan'
import { PATH as PATH_AUTH } from 'src/pages/auth'

export const roundingUpMinutes = (time) => {
  const minutes = parseInt(time.format('mm'))
  if (_.includes([0, 30, 60], minutes)) {
    return time
  }

  if (minutes < 30) {
    return time.add(30 - minutes, 'minute')
  }

  return time.add(60 - minutes, 'minute')
}

export const roundingDownMinutes = (time) => {
  const minutes = parseInt(time.format('mm'))
  if (_.includes([0, 30, 60], minutes)) {
    return time
  }

  if (minutes < 30) {
    return time.add(-minutes, 'minutes')
  }

  return time.add(-minutes + 30, 'minutes')
}

export const formatNumPrice = (value) => {
  const formattedString = Math.ceil(value)
    .toString()
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  return formattedString
}

export function diffBetweenTwoDates(startDate, endDate) {
  const start = moment(startDate).startOf('day')
  const end = moment(endDate).startOf('day')
  return start.diff(end, 'days')
}

export const resizeFile = (file): any => {
  if (file.size < 2 * 1024 * 1024) {
    return file
  }
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'file',
    )
  })
}

export const redirectFromState = (user, paidOrders) => {
  const state = user?.state || ''
  const transactions = user?.state_transitions || []
  const beforeState = _.get(user, 'state_transitions[0].before_state')

  if (state === USER_STATE.REQUESTING_SMS_VERIFICATION) {
    return PATH_AUTOMATIC_PLAN.SMS_AUTH
  }

  if (
    beforeState === USER_STATE.SHAMPOO_NOT_PAID &&
    state === USER_STATE.SHAMPOO_REGULAR_PLAN
  ) {
    return PATH_REGULAR_SHAMPOO.REGULAR_SUBSCRIPTION_ADDRESS
  }

  if (state === USER_STATE.SUBSCRIPTION_SUCCESS_PAID) {
    return PATH_REGULAR_SHAMPOO.REGULAR_SUBSCRIPTION_ADDRESS // WH4
  }

  if (
    state === USER_STATE.SUBSCRIPTION_SMS_VERIFICATION ||
    state === USER_STATE.KARTE_SMS_VERIFICATION
  ) {
    return PATH_AUTOMATIC_PLAN.SMS_AUTH // WH5
  }

  if (paidOrders !== 0) {
    return PATH_MY_PAGE.PURCHASE_HISTORY
  }

  const feedbackId = _.get(user, 'last_doctor_feedback.id', 0)
  const channelToken = _.get(user, 'reservations[0].channel_token', '')

  if (state === USER_STATE.PATIENT_PROVISIONALLY_REGISTERED) {
    return PATH_AUTH.MEDICAL_SPECIALTY // WB2
  }

  if (state === USER_STATE.CHOICE_INTERVIEW_METHOD) {
    return PATH_AUTOMATIC_PLAN.MEDICAL_EXAMINATION // WB5-1
  }

  if (state === USER_STATE.SHAMPOO_NOT_PAID) {
    return PATH_REGULAR_SHAMPOO.CONFIRM_DELIVERY
  }

  if (state === USER_STATE.REGISTERED_INTERVIEW) {
    return PATH_AUTOMATIC_PLAN.PRECAUTION
  }

  if (state === USER_STATE.NO_NEED_INTERVIEW) {
    return PATH_AUTOMATIC_PLAN.PROPOSAL
  }

  if (state === USER_STATE.SUBSCRIPTION_SUGGESTIONS) {
    return PATH_AUTOMATIC_PLAN.SUGGEST_SUBSCRIPTION
  }

  if (state === USER_STATE.CHAT_INTERVIEW_SCHEDULED) {
    return PATH_CHAT
  }

  if (state === USER_STATE.VIDEO_INTERVIEW_SCHEDULED) {
    return PATH_VIDEO.VIDEO_INTERVIEW
  }

  if (state === USER_STATE.OTP_VERIFIED || state === USER_STATE.NOT_PAID) {
    if (beforeState === USER_STATE.PATIENT_PROVISIONALLY_REGISTERED) {
      return PATH_REGULAR_SHAMPOO.REGULAR_SUBSCRIPTION
    }
    if (beforeState === USER_STATE.REQUESTING_SMS_VERIFICATION) {
      return PATH_PAYMENT.PAYMENT_SUCCESS
    }
    return lookBackState(transactions, feedbackId)
  }

  if (state === USER_STATE.CONFIRMED_RESERVATION) {
    return PATH_VIDEO.COMPLETE_TEMPORARY_RESERVE
  }

  if (state === USER_STATE.VIDEO_INTERVIEW_DATE) {
    return `/video/incoming/${channelToken}`
  }

  if (state === USER_STATE.CONFIRMING_PRESCRIPTION) {
    return `/karte-plan/prescription-proposal/${feedbackId}` //WF2
  }

  if (state === USER_STATE.IDENTITY_VERIFICATION_NOT_REGISTERED) {
    return PATH_AUTOMATIC_PLAN.ADDRESS_ENTRY
  }

  if (state === USER_STATE.IDENTIFICATION_NG) {
    return PATH_AUTOMATIC_PLAN.IDENTIFICATION
  }

  if (state === USER_STATE.IDENTITY_CHECK) {
    return PATH_AUTOMATIC_PLAN.COMPLETE_IDENTIFICATION
  }

  if (state === USER_STATE.IDENTIFICATION_OK) {
    return PATH_AUTOMATIC_PLAN.COMPLETE_IDENTIFICATION
  }

  if (state === USER_STATE.SHAMPOO_REGULAR_PLAN) {
    return `${PATH_PAYMENT.PAYMENT_SUCCESS}?type=${PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION}`
  }

  if (state === USER_STATE.PRESCRIPTION_OK) {
    return PATH_MY_PAGE.PURCHASE_HISTORY
  }

  if (state === USER_STATE.SUBSCRIPTION_SUGGESTIONS) {
    return PATH_AUTOMATIC_PLAN.SUGGEST_SUBSCRIPTION // WB6
  }

  if (state === USER_STATE.SUCCESS_PAID) {
    return PATH_AUTOMATIC_PLAN.ADDRESS_ENTRY // TODO
  }

  if (state === USER_STATE.SUBSCRIPTION_NOT_PAID) {
    return PATH_REGULAR_SHAMPOO.REGULAR_SUBSCRIPTION // WH1
  }

  if (state === USER_STATE.SUBSCRIPTION_CONTRACT) {
    return PATH_PAYMENT.PAYMENT_SUCCESS // WJ2
  }

  if (state === USER_STATE.DOCTOR_PRESCRIPTION_OK) {
    return PATH_MY_PAGE.PURCHASE_HISTORY // WJ2
  }

  return ''
}

export const lookBackState = (transactions, feedbackId) => {
  let result = ''
  transactions.length > 0 &&
    transactions.every((item) => {
      if (item.state === USER_STATE.NO_NEED_INTERVIEW) {
        result = PATH_PAYMENT.PAYMENT_SUCCESS
        return false
      }

      if (item.state === USER_STATE.CHOICE_INTERVIEW_METHOD) {
        if (feedbackId) {
          if (_.get(transactions, '2.state') === USER_STATE.SUCCESS_PAID) {
            //success_paid <- karte_sms_verification <- otp_verified
            result = PATH_AUTOMATIC_PLAN.IDENTIFICATION
            return false
          } else {
            result = `/karte-plan/prescription-proposal/${feedbackId}`
            return false
          }
        } else {
          if (
            _.some(
              transactions,
              (data) => data.state === USER_STATE.OTP_VERIFIED,
            )
          ) {
            result = PATH_MY_PAGE.OTHER_MENU
            return false
          } else {
            result = PATH_AUTOMATIC_PLAN.MEDICAL_EXAMINATION
            return false
          }
        }
      }

      if (item.state === USER_STATE.SUBSCRIPTION_SUGGESTIONS) {
        result = PATH_REGULAR_SHAMPOO.REGULAR_SUBSCRIPTION
        return false
      }

      if (item.before_state === USER_STATE.PATIENT_PROVISIONALLY_REGISTERED) {
        result = PATH_AUTH.MEDICAL_SPECIALTY
        return false
      }

      return true
    })
  return result
}

export const getUserPlanType = (transactions) => {
  let planType = ''

  transactions &&
    transactions.every((item) => {
      if (item.state === USER_STATE.NO_NEED_INTERVIEW) {
        planType = PLAN_TYPE.EXAMINATION
        return false
      }

      if (item.state === USER_STATE.CHOICE_INTERVIEW_METHOD) {
        planType = PLAN_TYPE.PRESCRIPTION
        return false
      }

      if (item.state === USER_STATE.SUBSCRIPTION_SUGGESTIONS) {
        planType = PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION
        return false
      }

      return true
    })

  return planType
}

export const handleLinkFromMail = (location, state, userTreatment) => {
  let route: string = ''
  switch (location.pathname) {
    case PATH_REGULAR_SHAMPOO.REGULAR_SUBSCRIPTION: {
      if (userTreatment.state !== USER_STATE.SUBSCRIPTION_SUGGESTIONS) {
        route = PATH_ERROR.STATUS_ERROR
      }
      break
    }
    case PATH_VIDEO.VIDEO_INTERVIEW: {
      if (userTreatment.state !== USER_STATE.VIDEO_INTERVIEW_SCHEDULED) {
        route = PATH_ERROR.STATUS_ERROR
      }
      break
    }
    case PATH_KARTE_PLAN.PRESCRIPTION_PROPOSAL: {
      if (userTreatment.state !== USER_STATE.CONFIRMING_PRESCRIPTION) {
        route = PATH_ERROR.STATUS_ERROR
      }
      break
    }
    case PATH_AUTOMATIC_PLAN.IDENTIFICATION: {
      if (
        state !== USER_STATE.IDENTIFICATION_OK &&
        state !== USER_STATE.IDENTITY_VERIFICATION_NOT_REGISTERED &&
        state !== USER_STATE.IDENTIFICATION_NG &&
        state !== USER_STATE.IDENTITY_CHECK
      ) {
        route = PATH_ERROR.STATUS_ERROR
      }
      break
    }
    default: {
      route = ''
    }
  }
  return route
}

export const randomString = (length = 11) => {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length + 1; i++) {
    result +=
      characters &&
      characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export const years = () => {
  const currYear = new Date().getFullYear()
  const lastYear = 1930
  return Array.from({ length: currYear - 1930 }, (_, index) => {
    return `${lastYear + index}年`
  }).reverse()
}

export const months = () => {
  return Array.from({ length: 12 }, (_, index) => {
    return `${index + 1}月`
  })
}
