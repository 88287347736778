import React from 'react'
import LocalStorage from 'src/utils/LocalStorage'

export type propTypes = {
  className?: string
  menuNameProp?: string
}

const MenuNameComponent = (props: propTypes) => {
  const { className, menuNameProp } = props

  const menuName = LocalStorage.getMenuName() || menuNameProp

  return (
    <>
      {menuName && (
        <div className={`fs-15 fw-400 ${className}`}>診療科目：{menuName}</div>
      )}
    </>
  )
}

export default MenuNameComponent
