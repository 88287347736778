export const polices = [
  {
    id: 1,
    title: '法規制の遵守',
    content:
      '当社は、個人情報の収集、利用、開示及び保護に関する法令、規則、契約及びその他の規範を遵守します。',
  },
  {
    id: 2,
    title: ' 個人情報の取得について',
    content:
      '当社は、本件サービスに関連して、以下の情報を適法、公正かつ適切な方法で収集しています。なお、当社が収集する情報の中には、個人情報ではないものもあり、その場合、個人情報保護に関する義務や責任を負うものではありません。',
    items: [
      {
        id: 1,
        content:
          '本件サービスの利用者に係る氏名、生年月日、性別、住所、メールアドレス、パスワード',
        count: '①',
      },
      {
        id: 2,
        content:
          '本件サービスの利用者に係る免許証、保険証、マイナンバーカード等の本人確認書類に記載された情報',
        count: '②',
      },
      {
        id: 3,
        content: '本件サービスの利用者に係るクレジットカードの情報',
        count: '③',
      },
      {
        id: 4,
        content:
          '本件サービスの利用者が予約時に選択した診療科目の種類、予約時に入力した問診票の内容、診療の予約日時、診療を担当する医師の氏名',
        count: '④',
      },
      {
        id: 5,
        content: '本件サービスの利用者が購入した医薬品その他商品に関する情報',
        count: '⑤',
      },
      {
        id: 6,
        content:
          '本件サービスの利用者及び利用者以外の者が電話、電子メール、その他の手段で当社にご意見、ご質問、アンケートへの回答などを提供された場合、その内容に関する情報',
        count: '⑥',
      },
      {
        id: 7,
        content: 'その他お客様から当社に提供されたすべての情報',
        count: '⑦',
      },
    ],
  },
  {
    id: 3,
    title: '個人情報の収集、使用、開示への同意',
    content: '',
    paragraphs: [
      {
        id: 1,
        title:
          '当社は、(ａ)お客様が個人情報の収集目的を通知され若しくは収集目的に書面で同意した後に、お客様が直接若しくはお客様から当社にお客様の個人情報を開示することを正式に許可された第三者を介して、お客様から自発的に当社に提供された場合、又は、(ｂ)同意なしにお客様の個人情報を収集及び使用することが適用される法律で許可又は要求された場合を除き、お客様の個人情報を収集しません。当社は、追加の個人情報を収集する前、及びお客様に通知されていない目的でお客様の個人情報を使用する前に、お客様の同意を求めるものとします（法律で許可又は承認されている場合を除く）。',
      },
      {
        id: 2,
        title:
          ' 当社の各種サービスにアクセスもしくは使用し、又は当社にお客様の個人情報を提供することにより、当社は、お客様が本プライバシーポリシーの条件を理解し、これに同意し、当社によるお客様の個人情報の収集、使用及び開示に同意したものとみなすことができます。これらの条件のいずれかに同意されない場合は、当社の各種サービスにアクセスしたり、使用したり、お客様の個人情報を当社に提供したりしないでください。',
      },
    ],
  },
  {
    id: 4,
    title: '利用目的',
    content:
      '当社は、以下の利用目的の達成の必要な範囲内で、取得した個人情報を収集・利用することがあります。',
    items: [
      {
        id: 1,
        content: '本件サービスの提供、維持、改善（医薬品の発送を含む）',
        count: '①',
      },
      {
        id: 2,
        content: '本件サービスで販売される商品及びサービス等の決済処理',
        count: '②',
      },
      {
        id: 3,
        content: 'お客様の本人確認',
        count: '③',
      },
      {
        id: 4,
        content: '各種サービス及びキャンペーンのご案内',
        count: '④',
      },
      {
        id: 5,
        content: '各種サービス向上のための統計データの作成',
        count: '⑤',
      },
      {
        id: 6,
        content: '各種サービス向上のための調査・アンケートの実施と集計',
        count: '⑥',
      },
      {
        id: 7,
        content:
          '違法行為、不正行為、当社の利用規約等に違反する行為またはこれらの可能性のある行為の防止、調査、または対策措置の立案',
        count: '⑦',
      },
      {
        id: 8,
        content: 'お客様からの問い合わせへの対応',
        count: '⑧',
      },
    ],
  },
  {
    id: 5,
    title: '委託先への提供',
    content:
      '当社は、利用目的の達成に必要な範囲内で、個人情報の全部又は一部の管理を業務委託先に委託する場合があります。当社は、委託先の管理を極めて重要と考えており、業務を委託する際には、適用される法律で定められた個人情報保護水準を満たす事業者を選定し、秘密保持契約等により相互の義務を明確にしています。また、個人情報を適切に取り扱うよう関係事業者に要請するとともに、個人情報の安全管理のために必要な監督・監査を継続的に行います。',
  },
  {
    id: 6,
    title: '第三者提供',
    paragraphs: [
      {
        id: 1,
        title:
          '当社は、以下の場合を除いて、お客様の同意なく個人情報を第三者に提供しません。なお、個人情報を第三者に提供する場合には、適用される法令に基づき、適切に行います。',
        items: [
          {
            id: 1,
            content: 'お客様の同意を得た場合',
            count: '①',
          },
          {
            id: 2,
            content:
              '「４．利用目的」の達成に必要な範囲内で、個人情報の全部又は一部の管理を業務委託先に委託する場合',
            count: '②',
          },
          {
            id: 3,
            content:
              '合併、事業譲渡その他の事由による事業の承継に伴い、当該事業を承継する者に対して開示する場合',
            count: '③',
          },
          {
            id: 4,
            content:
              '裁判所、検察庁、警察、税務署、弁護士会、その他これらに準じた権限を有する機関から、個人情報の開示を求められた場合',
            count: '④',
          },
          {
            id: 5,
            content: 'その他法令で認められている場合',
            count: '⑤',
          },
          {
            id: 6,
            content: '当社の各種サービスごとに別途定める場合',
            count: '⑥',
          },
        ],
      },
      {
        id: 2,
        title:
          '当社は、お客様の事前の同意に基づき個人情報を第三者に提供した場合、以下の事項に関する記録を作成し、保管します。',
        items: [
          {
            id: 1,
            content: '当該個人情報を提供した年月日',
            count: '①',
          },
          {
            id: 2,
            content:
              '当該第三者の氏名又は名称その他の当該第三者を特定するに足りる事項',
            count: '②',
          },
          {
            id: 3,
            content:
              '当該個人情報によって識別される者の氏名その他のその者を特定するに足りる情報',
            count: '③',
          },
          {
            id: 4,
            content: '当該個人情報の項目',
            count: '④',
          },
        ],
      },
      {
        id: 3,
        title:
          '当社は、お客様の事前の同意に基づき、個人情報を利用目的の達成に必要な範囲内で、特定の国又は地域に提供することがあります。その場合、当社が個人情報の取扱いについて講じている措置と概ね同水準とする措置を講じている外国にある当社の子会社及び関係会社その他取引先である第三者に提供されます。',
        title_2:
          '当社が提供先とする特定の国又は地域の名称及び個人情報の保護に関する制度の情報については、<a class="text-decoration-underline" href="https://www.sheepmedical.com/provision-of-personal-information-to-third-parties-in-foreign-countries">こちら</a>をご覧ください。',
        title_3: '【外国にある第三者への個人情報の提供について】',
        title_4:
          'https://www.sheepmedical.com/provision-of-personal-information-to-third-parties-in-foreign-countries',
      },
    ],
  },
  {
    id: 7,
    title: '個人情報の共同利用',
    content:
      '当社並びに当社の子会社、関連会社及び提携先企業間では、次に掲げるとおり、利用目的の達成に必要な範囲内で、個人情報を共同利用する場合があります。なお、個人情報の管理についての責任は、当社が有します。',
    items: [
      {
        id: 1,
        content: '共同して利用される個人データの項目',
        content_2: '「2．個人情報の取得について」のとおり',
        count: '①',
      },
      {
        id: 2,
        content: '共同利用者の範囲',
        content_2: '・当社の子会社及び関連会社',
        count: '②',
        link: 'https://www.sheepmedical.com/about',
      },
      {
        id: 3,
        content: '共同利用における利用目的',
        content_2: '「4．利用目的」のとおり',
        count: '③',
      },
      {
        id: 3,
        content: '個人データの管理について責任を有する者の名称',
        content_2: 'SheepMedical株式会社',
        count: '④',
      },
    ],
  },
  {
    id: 8,
    title: 'クッキー（Cookie）の利用について',
    content:
      '当社は、Googleによるウェブサイトアクセス解析ツール「Googleアナリティクス」を使用しています。「Googleアナリティクス」はデータの収集のためにCookieを使用し、Cookieから取得できる以下の情報を、お客様の利便性向上及び利用状況の分析等のため利用する場合があります。これらのデータは匿名で収集されており、個人を特定するものではありません。お客様は、Cookieを無効にすることで収集を拒否することが出来ます。お使いのブラウザの設定をご確認ください。その他詳細については、<a href="https://policies.google.com/privacy?hl=ja">Googleプライバシーポリシー</a>をご確認ください',
    content_2: '【Cookieから取得できる情報】',
    content_3:
      '端末に関する情報、インターネットへの接続に関する情報、リファラー情報、IPアドレス情報、タイムスタンプ情報、広告識別子情報等',
  },
  {
    id: 9,
    title:
      '開示、訂正・追加・削除、利用停止・消去、第三者提供の停止・第三者提供記録の開示',
    content:
      'お客様は、当社に対し、以下の場合を除き、当社が別途定めた手続に従って、お客様の個人情報の利用目的の通知及び個人情報の開示、訂正・追加・削除、利用停止・消去、第三者提供の停止・第三者提供記録の開示を請求することができます。この場合、当社は、お客様ご本人からの請求であることを確認したうえで、遅滞なく調査を行い、その結果に基づき当該個人情報の訂正・追加・削除、利用停止・消去、第三者提供の停止・第三者提供記録の開示を行います。この場合、当社は、個人情報の保護に関する法律に従って適切に対応いたします。なお、個人情報の開示の請求にあたっては、当社が別途定めた手続に従って開示手数料を頂くことがあります。',
    items: [
      {
        id: 1,
        content:
          '開示することで本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合',
        count: '①',
      },
      {
        id: 2,
        content:
          '開示することで当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合',
        count: '②',
      },
      {
        id: 3,
        content: '開示することが法令に違反することとなる場合',
        count: '③',
      },
      {
        id: 4,
        content: '開示の請求がご本人からであることが確認できない場合',
        count: '④',
      },
    ],
  },
  {
    id: 10,
    title: '個人情報の安全管理',
    paragraphs: [
      {
        id: 1,
        title:
          '当社は、個人情報を厳重に管理し、個人情報保護のための適切な管理・物理・技術的施策の導入やシステムの開発・改善、従業員への教育・啓発活動、入退室管理、情報セキュリティ対策などを通じて、不正又は不適切なアクセス、収集、使用、開示、複写、変更、紛失、破壊、改ざん、漏洩などのリスクを防止するために、合理的なセキュリティ対策を講じます。',
      },
      {
        id: 2,
        title:
          '当社は、個人情報を収集した目的が達成されず、法律上又は業務上保有する必要がなくなったと考えるのが妥当である場合、又は適用される法律で要求される場合には、直ちに個人情報を含む文書の保有を中止し、又は個人情報とお客様との関連付けを解除します。また、保持していない個人情報は、適切な方法で廃棄、匿名化、及び／又は削除されます。 ',
      },
    ],
  },
  {
    id: 11,
    title: '継続的改善',
    content:
      '当社は、ビジネスパートナーからの要請や社会情勢の変化に応じて、個人情報の適切な取扱いと保護を維持するために、本方針を含む個人情報保護マネジメントシステムを継続的に見直し、改善していきます。',
  },
  {
    id: 12,
    title: 'プライバシーポリシーの更新',
    content:
      '当社は、お客様の個人情報の取り扱いについて、適宜見直し、改善してまいります。その場合、すべての改訂は本サイトでお知らせします。本プライバシーポリシーの変更は、当社が本サイトに掲載した時点から適用されるものとします。',
  },
  {
    id: 13,
    title: 'お問合せ連絡先',
    content:
      '本プライバシーポリシーに関して不明な点がある場合、各種サービスにおける個人情報の取扱いに関する質問等がある場合又は個人情報の開示、訂正・追加・削除、利用停止・消去、第三者提供の停止等の請求を行う場合は、以下よりご連絡ください。',
  },
]
