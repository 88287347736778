import ListTreatmentCancelContainer, {
  PATH as LIST_TREATMENT_CANCEL,
} from './ListTreatmentCancel'

export default {
  ListTreatmentCancelContainer,
}
export const PATH = {
  LIST_TREATMENT_CANCEL,
}
