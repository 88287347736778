import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import qs from 'query-string'

import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import ButtonCustom from 'src/components/form/Button'
import LocalStorage from 'src/utils/LocalStorage'
import { useAppDispatch, useAppSelector } from 'src/store'
import MenuNameComponent from 'src/components/common/MenuNameComponent'
import {
  checkPrecautionInfo,
  getPrecautionsByMenuId,
} from 'src/useCase/precaution'
import { getInfo } from 'src/useCase/user'

const PrecautionContainer: React.FC = (props: any) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [isDisable, setIsDisable] = useState(true)
  const [isChecked, setIsChecked] = useState(false)
  const loading = useAppSelector(
    (state) => state.domain.automaticPlan.questions.loading,
  )
  const precautionList = useAppSelector(
    (state) => state.domain.automaticPlan.precautions.entity,
  )
  const params = qs.parse(location.search)
  const menuId = params.menu_id

  useEffect(() => {
    dispatch(
      getInfo({
        with: 'userTreatments,userTreatments.reservations,userTreatments.reservations.answers,userTreatments.userTreatmentTransitions',
      }),
    )
    dispatch(
      getPrecautionsByMenuId({
        menu_id: menuId?.toString() || '',
      }),
    )
  }, [])

  const handleClick = () => {
    const newCheckedState = !isChecked
    setIsChecked(newCheckedState)
    setIsDisable(!newCheckedState)
  }

  const handleSubmit = () => {
    dispatch(checkPrecautionInfo())
  }
  const back = () => {
    LocalStorage.removePrecaution()
    navigate(-1)
  }

  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form precautions">
        <StepsHeader
          step={10}
          stepActive={2}
          stepActiveName={'問診・注意事項確認'}
          className="top-70"
        />
        <HeaderStyle>注意事項の確認</HeaderStyle>
        <MenuNameComponent className="mb-15" />
        <div className="mb-20">
          <p>必ず以下の注意事項をご確認ください</p>
          <p>
            {
              'すべての注意事項にご同意いただけない場合当サービスのご利用が​できません'
            }
          </p>
        </div>

        <PreCautionDiv>
          {precautionList &&
            precautionList.map((precaution, index) => {
              return (
                <label className="checkbox-label" key={precaution.id}>
                  <div
                    className="checkbox-title"
                    dangerouslySetInnerHTML={{ __html: precaution.content }}
                  ></div>
                </label>
              )
            })}

          <label className="checkbox-label checkbox-all">
            <div>
              <input
                type="checkbox"
                onClick={() => handleClick()}
                className={`checkbox-checkmark ${isChecked ? 'checked' : ''}`}
              />
              <span
                className={`checkbox-checkmark ${isChecked ? 'checked' : ''}`}
              ></span>
              <span>上記の注意事項に同意します</span>
            </div>
          </label>
        </PreCautionDiv>

        <ButtonCustom
          className="btn-default btn-chestnut-rose my--30"
          loading={loading}
          disabled={isDisable}
          onClick={handleSubmit}
          name="同意して次へ進む"
          showIconRight
        />
        <ButtonCustom
          className="btn-default btn-white"
          loading={false}
          onClick={back}
          name="戻る"
          showIconRight
        />
      </div>
    </>
  )
}

const PreCautionDiv = styled.div`
  background-color: #ffffff;
  /* title */
  .title {
    margin-top: 1.25rem;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.13em;
    color: #000000;
  }
  .title-sub {
    margin-top: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: #000000;
  }
  .description {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #000000;
  }

  /* checkbox */
  .checkbox-label {
    display: block;
    position: relative;
    padding-left: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #000000;
    user-select: none;
    border-bottom: 1px solid #f1efe7;
    padding-bottom: 15px;
    margin-top: 15px;
    cursor: pointer;

    &.checkbox-all {
      padding-left: 40px;
      border-bottom: none;
      display: inline-flex;
      align-items: center;

      span {
        line-height: 32px;
      }
    }

    .checkbox-title {
      a {
        color: hsl(209, 100%, 37.5%);
      }
    }
    .checkbox-checkmark:after {
      left: 11px;
      top: 4px;
      width: 10px;
      height: 18px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
    /* input:checked ~ .checkbox-checkmark {
        background-color: var(--color-yuma);
        border: 1px solid var(--color-yuma);
      }
      input:checked ~ .checkbox-checkmark:after {
        display: block;
      } */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkbox-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 32px;
      width: 32px;
      background-color: var(--color-spring-wood);
      border-radius: 5px;
      /* border: 1px solid; */
    }
    .checkbox-checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  /* submit */
  .precautions-submit {
    margin-top: 60px;
    width: 100%;
    padding: 19px;
    color: #ffffff;
    background: #000000;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 55px;
  }
  .precautions-link {
    color: hsl(209, 100%, 37.5%);
  }
  .color-blue {
    color: #15a1f0 !important;
  }
  button:disabled {
    background: #d9dee2 !important;
    cursor: not-allowed;
  }
  .checked {
    background-color: var(--color-yuma) !important;
    border: 1px solid var(--color-yuma) !important;
  }
  .checkbox-checkmark {
    &.checked:after {
      display: block;
    }
  }
`

export default PrecautionContainer
