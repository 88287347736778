import React from 'react'

import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import MedicalSpecialtyForm from './components/MedicalSpecialtyForm'

const MedicalSpecialtyContainer: React.FC = () => {
  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form not-padding-p">
        <StepsHeader
          step={10}
          stepActive={2}
          stepActiveName="問診・注意事項確認"
          className="top-70"
        />
        <HeaderStyle>診療科目選択</HeaderStyle>
        <MedicalSpecialtyForm />
      </div>
    </>
  )
}

export default MedicalSpecialtyContainer
