import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import _ from 'lodash'

import { FormValues, useSchedule } from './useSchedule'
import { DATE_TIME, DATE_TIME_FORMAT } from 'src/constants/app'
import DatePickerField from 'src/components/form/DatePickerField'
import { useAppDispatch, useAppSelector } from 'src/store'
import SelectField from 'src/components/form/SelectField'
import { IBusyTimeParam, busyTime, setBusyTime } from 'src/useCase/video'
import ButtonCustom from 'src/components/form/Button'
import LocalStorage from 'src/utils/LocalStorage'
import InputField from 'src/components/form/InputField'

const Container: React.FC = () => {
  const dispatch = useAppDispatch()
  const { form, onSubmit } = useSchedule()
  const { control, handleSubmit, setValue } = form
  const [showPhone, setShowPhone] = useState<boolean>(
    () => LocalStorage.getLocalStorage('show_Phone') !== 'false',
  )

  const user = useAppSelector((state) => state.ui.app.authInfo)
  const dataWorkingDates = useAppSelector(
    (state) => state.domain.video.workingDate,
  )
  const dataDoctorFreeTime = useAppSelector(
    (state) => state.domain.video.doctorFreeTime,
  )

  const listPhoneOS = [
    { value: 'ios', label: 'iphone' },
    { value: 'android', label: 'iphone以外のスマートフォン' },
    { value: 'other', label: 'スマートフォン以外の携帯' },
  ]

  useEffect(() => {
    dispatch(setBusyTime([]))
  }, [])

  useEffect(() => {
    const defaultOption = _.get(dataDoctorFreeTime, 'entity[0].value', null)
    setValue('time', defaultOption)
  }, [dataDoctorFreeTime])

  useEffect(() => {
    setValue('phone', user?.phone)
    setValue('phoneos', user?.os_type)
    const shouldShowPhone = !user?.phone?.length || !user?.os_type?.length
    setShowPhone(shouldShowPhone)
    LocalStorage.setLocalStorage('show_phone', shouldShowPhone)
  }, [user])

  const disabledDate = (current) => {
    return !_.includes(
      _.map(dataWorkingDates.entity, 'date'),
      current.format(DATE_TIME),
    )
  }

  const onChangeDate = (event) => {
    let params: IBusyTimeParam = { date: event.target.value }
    params.user_treatment_id = LocalStorage.getUserTreatmentId() || undefined
    if (LocalStorage.getLocalStorage('doctor_request_link_data')) {
      const doctorRequestData = LocalStorage.getLocalStorage(
        'doctor_request_link_data',
      )
      params = {
        ...params,
        doctor_id: doctorRequestData?.doctor_id,
        user_treatment_id: doctorRequestData?.user_treatment_id,
      }
    }

    dispatch(busyTime({ params }))
  }

  const handleSubmitWithExtraData = (extraData: any) =>
    handleSubmit((data) => onSubmit(data, extraData))

  return (
    <>
      <Form onFinish={handleSubmitWithExtraData(showPhone)}>
        <div>
          <div className="mt-20 d-flex justify-content-between border-bottom-lucky mb-30 pb-5">
            <span className="fs-15">ご希望の日</span>
            <span className="text-stiletto fs-11">
              ※明日から2週間後まで選択可能です。
            </span>
          </div>
          <DatePickerField<FormValues>
            controller={{
              name: 'date',
              control,
              rules: {
                onChange: onChangeDate,
              },
            }}
            datePickerProps={{
              format: DATE_TIME_FORMAT,
              disabledDate: disabledDate,
              allowClear: false,
            }}
          />
          <SelectField<FormValues>
            className="border-top-lucky pt-15"
            label="ご希望の時間"
            selectProps={{
              options: dataDoctorFreeTime.entity,
              loading: dataDoctorFreeTime.loading,
              allowClear: false,
              placeholder: '選択してください',
            }}
            controller={{ name: 'time', control }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          />
          {showPhone ? (
            <>
              <div className="border-top-lucky pt-15">
                <p className="mb-1">
                  ビデオ診察当日の緊急連絡先として、お客様の電話番号をお教えください。
                </p>
                <p>
                  ※主に医師にビデオ通話が繋がらなかった場合のコミュニケーションに利用いたします。
                </p>
              </div>
              <div className="mt-20 d-flex justify-content-between pb-5">
                <span className="fs-15">電話番号</span>
              </div>
              <InputField<FormValues>
                controller={{
                  control,
                  name: 'phone',
                }}
                inputProps={{
                  placeholder: '入力してください',
                }}
              />
              <div className="border-top-lucky pt-15">
                ビデオ診察実施時の端末はスマホを推奨しています。当日のサポートに利用しますので、お使いのスマートフォンの種類を教えてください。
              </div>
              <SelectField<FormValues>
                className="pt-15"
                label="スマートフォンの種類"
                selectProps={{
                  options: listPhoneOS,
                  // loading: dataDoctorFreeTime.loading,
                  allowClear: false,
                  placeholder: '選択してください',
                }}
                controller={{ name: 'phoneos', control }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              />
            </>
          ) : (
            <></>
          )}
        </div>
        <ButtonCustom
          className="btn-default btn-chestnut-rose fs-20 mt-60 w-100 mb-4"
          loading={false}
          onClick={handleSubmitWithExtraData(showPhone)}
          htmlType="submit"
          showIconRight
          name={
            showPhone && !user?.otp_verified_at
              ? 'SMS認証・予約の確認画面へ進む'
              : '予約の確認画面へ'
          }
        />
      </Form>
    </>
  )
}

export default Container
