import { useForm } from 'react-hook-form'
import { UserRequestInterface } from 'src/interfaces/user'
import { useAppDispatch } from 'src/store'
import { register, updateCurrentFlow } from 'src/useCase/user'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { EMAIL_REGEX, KANA_REGEX } from 'src/utils/regex'
import LocalStorage from 'src/utils/LocalStorage'

export const useRegister = () => {
  const dispatch = useAppDispatch()

  const defaultValues: UserRequestInterface = {
    family_name: '',
    given_name: '',
    family_name_kana: '',
    given_name_kana: '',
    date_of_birth: '1980-01-01',
    gender_id: '',
    email: '',
    clinic_uuid: '',
  }

  const validationSchema = Yup.object().shape({
    family_name: Yup.string()
      .required('入力してください。')
      .max(50, '姓は30文字以下で入力してください'),
    given_name: Yup.string()
      .required('入力してください。')
      .max(100, '名は30文字以下で入力してください'),
    family_name_kana: Yup.string()
      .required('入力してください。')
      .max(50, '姓(カナ)は30文字以下で入力してください')
      .matches(KANA_REGEX, 'カナで入力してください。'),
    given_name_kana: Yup.string()
      .required('入力してください。')
      .max(100, '名(カナ)は30文字以下で入力してください')
      .matches(KANA_REGEX, 'カナで入力してください。'),
    date_of_birth: Yup.string().required('入力してください。'),
    email: Yup.string()
      .required('入力してください。') //必須項目が入力されていません
      .email('メールアドレスを正しく入力してください')
      .matches(EMAIL_REGEX, 'メールアドレスを正しく入力してください'),
    gender_id: Yup.string().required('選択してください。'),
    // phone: Yup.string().required('入力してください。'),
  })

  const onSubmit = async (
    data: UserRequestInterface,
    redirectPath: string,
    currentFlow: string | null,
  ) => {
    data.clinic_uuid = LocalStorage.getClinicUuid()
    await dispatch(
      register({ credentials: data, meta: form, redirectPath: redirectPath }),
    )
    if (currentFlow && LocalStorage.accessToken) {
      await dispatch(updateCurrentFlow({ current_flow: currentFlow }))
    }
  }

  const form = useForm<UserRequestInterface>({
    defaultValues,
    shouldUnregister: false,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}
