import React from 'react'

import ListTreatmentCancel from './components/ListTreatment'

const ListTreatmentCancelContainer: React.FC = () => {
  return (
    <>
      <ListTreatmentCancel />
    </>
  )
}

export default ListTreatmentCancelContainer
