import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'query-string'

// store
import { useAppDispatch, useAppSelector } from 'src/store'

// enums
import { USER_STATE } from 'src/enum/userStatus'
import { USER_TREATMENT_STATE } from 'src/enum/userTreatmentState'

// components
import CommonModal from 'src/components/Modal/CommonModal'
import LPHeader from 'src/components/layout/Header/LPHeader'

// use case
import { handleRedirectByState } from 'src/useCase/keepFlow'
import {
  cancelUserTreatment,
  getUserTreatmentList,
  updateCallState,
} from 'src/useCase/userTreatment'

// images
import newRight from 'src/assets/icons/new-right.svg'
import ConfirmPlanModal from 'src/components/Modal/ConfirmPlanModal'
import { Select, Space } from 'antd'
import ButtonCustom from 'src/components/form/Button'
import VideoCallModal from 'src/components/Modal/VideoCallModal'
import { PATH as OTHER_MENU_PATH } from 'src/pages/myPage/OtherMenu'

const { Option } = Select
const FAILED = 'failed'
export type paramsType = {
  with: string
  not_state?: string
  has_request_call?: boolean
}
const Container: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const urlParams = qs.parse(location.search)
  const user = useAppSelector((state) => state.ui.app.authInfo)
  const userTreatments = useAppSelector(
    (state) => state.domain.myPage.userTreatment.entities,
  )
  const PAID_STATUS = [
    USER_TREATMENT_STATE.SUBSCRIPTION_SUCCESS_PAID,
    USER_TREATMENT_STATE.SUCCESS_PAID,
    USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_CHECK,
  ]

  const [openModal, setOpenModal] = useState(false)
  const [treatmentState, setTreatmentState] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false)
  const [selectedUserTreatmentId, setSelectedUserTreatmentId] = useState('')
  const [isSubmitCancelTreatment, setIsSubmitCancelTreatment] = useState(false)
  const [stateBefore, setStateBefore] = useState('')
  const [isVideoCallModal, setIsVideoCallModal] = useState(false)
  const [doctorRequestDate, setDoctorRequestDate] = useState<
    string | undefined
  >('')
  const [menuName, setMenuName] = useState('')

  const handleModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = async () => {
    try {
      setIsSubmitCancelTreatment(true)
      const res = await cancelUserTreatment(selectedUserTreatmentId)
      setStateBefore(res?.data?.data?.state_before)
      getUserTreatments()
      setIsModalConfirmOpen(true)
    } catch (err: any) {
      setStateBefore(FAILED)
      setIsModalConfirmOpen(true)
    } finally {
      setIsSubmitCancelTreatment(false)
      setIsModalOpen(false)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleChange = (value: any) => {
    if (value) {
      setSelectedUserTreatmentId(value)
    }
  }

  const getUserTreatments = () => {
    const notState = [
      USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_OK,
      USER_TREATMENT_STATE.SUBSCRIPTION_CONTRACT,
      USER_TREATMENT_STATE.CANCELED,
      USER_TREATMENT_STATE.DOCTOR_CANCEL_PRESCRIPTION,
    ]

    let params: paramsType = {
      with: 'menu,reservations,lastDoctorFeedback,lastOrder.plan,userTreatmentTransitions,userTreatments,userTreatments.reservations',
      not_state: notState.join(','),
      has_request_call: true,
    }

    if (urlParams.from === 'chat') {
      params = {
        ...params,
        not_state: USER_TREATMENT_STATE.CANCELED,
        has_request_call: false,
      }
    }

    dispatch(getUserTreatmentList(params))
  }

  const handleConfirm = () => {
    setIsVideoCallModal(false)
    if (selectedUserTreatmentId) {
      dispatch(
        updateCallState({ id: selectedUserTreatmentId, state: 'accepted' }),
      )
    }
    return navigate(OTHER_MENU_PATH)
  }

  const handleDecline = async () => {
    setIsVideoCallModal(false)
    if (selectedUserTreatmentId) {
      dispatch(
        updateCallState({ id: selectedUserTreatmentId, state: 'rejected' }),
      )
    }
    getUserTreatments()
  }

  useEffect(() => {
    getUserTreatments()
  }, [])

  useEffect(() => {
    if (urlParams.from === 'chat') {
      getUserTreatments()
    }
  }, [location.search])

  return (
    <Wrapper>
      <LPHeader />
      <div className="other-menu">
        <div className="w-full justify-center mt-20 mx-auto layout-webview">
          {userTreatments &&
            userTreatments.map((userTreatment, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedUserTreatmentId(userTreatment.id)

                    if (userTreatment.have_call_request_from_doctor) {
                      setIsVideoCallModal(true)
                      setDoctorRequestDate(userTreatment.doctor_request_date)
                      setMenuName(
                        userTreatment?.menu?.name ||
                          userTreatment.last_order?.plan?.name,
                      )
                      return
                    }

                    const link = handleRedirectByState(
                      userTreatment,
                      user,
                      urlParams,
                    )

                    if (link === 'popup') {
                      setTreatmentState(userTreatment.state)
                      return setOpenModal(true)
                    }

                    navigate(link)
                  }}
                  className="menu-item"
                >
                  <span className="d-flex justify-content-between pb-10">
                    <div className="flex-auto flex fw-bold">
                      <span className="menu-name cl-black">
                        {userTreatment?.menu?.name ||
                          userTreatment.last_order?.plan?.name}
                      </span>
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                      <img
                        style={{ width: '15px', height: '20px' }}
                        src={newRight}
                      />
                    </div>
                  </span>
                </div>
              )
            })}
        </div>
        <div className="d-flex justify-center mt-80">
          <div className="d-flex justify-center mx-auto">
            {urlParams.from !== 'chat' && userTreatments.length > 0 && (
              <button
                onClick={handleModal}
                type="button"
                className="btn btn-line"
              >
                手続きをキャンセルする方はこちら
              </button>
            )}
            {!userTreatments.length && (
              <p>契約している診療項目がありません。</p>
            )}
          </div>
        </div>
      </div>

      <VideoCallModal
        isOpen={isVideoCallModal}
        onSubmit={handleConfirm}
        onCancel={handleDecline}
        doctorRequestDate={doctorRequestDate || ''}
        menuName={menuName}
      />

      <ConfirmPlanModal isOpen={isModalOpen} onCancel={handleCancel}>
        <div className="p-15 text-center mt-20">
          <h3 className="text-title">
            キャンセルする診療科目を選択してください
          </h3>

          <Space wrap>
            <Select
              style={{
                width: 220,
                height: 60,
                textAlign: 'left',
                marginBottom: 10,
              }}
              onChange={handleChange}
            >
              {userTreatments &&
                userTreatments.map((userTreatment, index) => (
                  <Option value={userTreatment?.id} key={index}>
                    {userTreatment?.menu?.name}
                  </Option>
                ))}
            </Select>
          </Space>

          <p>キャンセルボタンを押すと、手続き中の内容がキャンセルされます。</p>
          <p>キャンセルする場合はボタンを押してください。</p>
          <ButtonCustom
            className="btn-default btn-confirm mt-3"
            loading={isSubmitCancelTreatment}
            onClick={handleOk}
            htmlType="submit"
            name="キャンセルする"
            showIconRight
          />
        </div>
      </ConfirmPlanModal>

      <CommonModal
        isOpen={isModalConfirmOpen}
        onClose={() => setIsModalConfirmOpen(false)}
      >
        {stateBefore && stateBefore !== FAILED ? (
          PAID_STATUS.includes(stateBefore) ? (
            <div className="p-15 text-center mt-20">
              <h3 className="text-title">キャンセルを承りました。</h3>
              <p>
                返金情報がクレジットカードに反映されるまで翌2営業日から翌6営業日お時間をいただきますので、ご了承ください。
              </p>
            </div>
          ) : (
            <div className="p-15 text-center mt-20">
              <h3 className="text-title">キャンセルを承りました。</h3>
            </div>
          )
        ) : (
          <div className="p-15 text-center mt-20">
            <h3 className="text-title">キャンセル処理ができませんでした。</h3>
            <p>以下サポート窓口までお問い合わせください。</p>
            <p>
              オンライン処方サポート：
              <a
                href="mailto:bioportplus@sheepmedical.com"
                className="color-text-blue"
              >
                bioportplus@sheepmedical.com
              </a>
            </p>
          </div>
        )}
      </CommonModal>

      <CommonModal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <div className="p-15 text-center mt-20">
          {treatmentState === USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD ? (
            <>
              <b className="fs-20">医師の診察が必要です</b>
              <br />
              <br />
              <span className="text-center">
                問診の結果、お客様につきましては医師の診察が必要となります
              </span>
              <br />
              <span className="text-center">
                後ほど医師からご連絡をお待ちください。
              </span>
            </>
          ) : treatmentState ===
            USER_TREATMENT_STATE.DOCTOR_RESERVE_PRESCRIPTION ? (
            <>
              <b className="fs-20">医師の処方確認中です。</b>
            </>
          ) : user?.new_state === USER_STATE.IDENTIFICATION_OK ||
            treatmentState ===
              USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_CHECK ? (
            <>
              <b className="fs-20">医師の最終処方確認中です</b>
              <br />
              <br />
              <span className="text-center pt-25">
                本人確認書類が完了しましたので、医師によって処方の最終確認を行っております。
              </span>
              <br />
              <span className="text-center">しばらくお待ち下さい。</span>
            </>
          ) : (
            ''
          )}
        </div>
      </CommonModal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #f1efe7;
  min-height: 100vh;
  .other-menu {
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: #000000;
      margin-top: 10px;
    }
    .menu-name {
      font-size: 15px;
      line-height: 32px;
    }
  }

  .menu-item {
    color: #000000;
    margin-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
    line-height: 40px;
  }

  .visibility-hidden {
    visibility: hidden;
  }

  .mt-80 {
    margin-top: 80px;
  }

  .btn-line {
    border: navajowhite;
    text-decoration: underline;
    cursor: pointer;
  }

  .text-title {
    font-weight: bold;
    margin: 20px;
  }
`

export default Container
