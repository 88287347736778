import React, { useEffect, useState } from 'react'
import {
  Navigate,
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import qs from 'query-string'
import _ from 'lodash'

import { PATH } from 'src/pages/auth/SignIn'
import LocalStorage from 'src/utils/LocalStorage'
import { KEY_FROM_MAIL } from 'src/App'
import { useAppDispatch, useAppSelector } from 'src/store'
import { createEchoConnection } from 'src/utils/echoService'
import { SOCKET_EVENT } from 'src/containers/Chat/constants'
import { PATH as PATH_CHAT } from 'src/pages/chat/Chat'
import {
  pushNotificationToOldState,
  setNumberOfUnreadNotice,
} from 'src/useCase/notification'
import { handleRedirectByState } from 'src/useCase/keepFlow'
import { PATH as AUTOMATIC_PLAN_PATH } from 'src/pages/automaticPlan'
import { USER_TREATMENT_STATE } from 'src/enum/userTreatmentState'
import { PATH as PAYMENT_SUCCESS } from 'src/pages/payment/Success'
import { PATH as OTHER_MENU_PATH } from 'src/pages/myPage/OtherMenu'
import { PATH as COMPLETE_IDENTIFICATION } from 'src/pages/automaticPlan/CompleteIdentification'
import { USER_STATE } from 'src/enum/userStatus'
import { PAGE_CAN_NOT_BACK_WHEN_PAID } from 'src/constants/page-not-back'
import VideoCallModal from 'src/components/Modal/VideoCallModal'
import { updateCallState } from 'src/useCase/userTreatment'

const AuthenticatedLayout: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const accessToken = LocalStorage.accessToken
  const params = qs.parse(location.search)
  const user = useAppSelector((state) => state.ui.app.authInfo)
  const [isVideoCallModal, setIsVideoCallModal] = useState(false)

  if (!accessToken && params.from !== KEY_FROM_MAIL) {
    return (
      <Navigate
        to={`${PATH}?redirect=${btoa(
          `${location.pathname}${location.search}`,
        )}`}
      />
    )
  }

  useEffect(() => {
    window.onpopstate = () => {
      if (LocalStorage.getLocalStorage('is_paying')) {
        return navigate(OTHER_MENU_PATH)
      }

      if (user?.user_treatments?.length) {
        const TREATMENT_STATE_NOT_BACK = [
          USER_TREATMENT_STATE.REGISTERED_INTERVIEW,
          USER_TREATMENT_STATE.SUCCESS_PAID,
          USER_TREATMENT_STATE.SUBSCRIPTION_SUCCESS_PAID,
        ]

        const userTreatmentId = LocalStorage.getUserTreatmentId()
        const userTreatment = _.find(
          user?.user_treatments,
          (treatment) => treatment.id == userTreatmentId,
        )

        if (
          user.new_state !== USER_STATE.IDENTIFICATION_NG &&
          _.includes(
            [
              USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_CHECK,
              USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD,
            ],
            userTreatment.state,
          )
        ) {
          if (user.new_state === USER_STATE.IDENTITY_CHECK) {
            return navigate(COMPLETE_IDENTIFICATION)
          }

          return navigate(OTHER_MENU_PATH)
        }

        if (
          matchPath(AUTOMATIC_PLAN_PATH.QUESTIONS, window.location.pathname) &&
          userTreatment.state !== USER_TREATMENT_STATE.SELECTED_TREATMENT
        ) {
          return navigate(
            `${AUTOMATIC_PLAN_PATH.PRECAUTION}?menu_id=${userTreatment.menu_id}`,
          )
        }

        if (
          (matchPath(PAYMENT_SUCCESS, window.location.pathname) ||
            matchPath(PAYMENT_SUCCESS, location.pathname)) &&
          userTreatment.state === USER_TREATMENT_STATE.SUBSCRIPTION_CONTRACT
        ) {
          return navigate(PAYMENT_SUCCESS)
        }

        if (
          userTreatment &&
          userTreatment.state &&
          _.includes(TREATMENT_STATE_NOT_BACK, userTreatment.state)
        ) {
          return navigate(handleRedirectByState(userTreatment, user, {}))
        }

        if (
          userTreatment &&
          userTreatment.already_paid &&
          (_.find(PAGE_CAN_NOT_BACK_WHEN_PAID, (page) =>
            matchPath(page, window.location.pathname),
          ) ||
            _.find(PAGE_CAN_NOT_BACK_WHEN_PAID, (page) =>
              matchPath(page, location.pathname),
            ))
        ) {
          return navigate(OTHER_MENU_PATH)
        }
      }
    }
  }, [user?.user_treatments])

  useEffect(() => {
    if (LocalStorage.getLocalStorage('is_paying')) {
      navigate(OTHER_MENU_PATH)
      window.history.pushState(location.state, OTHER_MENU_PATH)
      return
    }

    if (user?.user_treatments?.length) {
      const TREATMENT_STATE_NOT_BACK = [
        USER_TREATMENT_STATE.REGISTERED_INTERVIEW,
        USER_TREATMENT_STATE.SUCCESS_PAID,
        USER_TREATMENT_STATE.SUBSCRIPTION_SUCCESS_PAID,
      ]

      const userTreatment = _.find(
        user?.user_treatments,
        (treatment) => treatment.id == LocalStorage.getUserTreatmentId(),
      )

      if (userTreatment?.have_call_request_from_doctor) {
        setIsVideoCallModal(true)
        return
      }

      if (
        userTreatment &&
        userTreatment.already_paid &&
        (_.find(PAGE_CAN_NOT_BACK_WHEN_PAID, (page) =>
          matchPath(page, window.location.pathname),
        ) ||
          _.find(PAGE_CAN_NOT_BACK_WHEN_PAID, (page) =>
            matchPath(page, location.pathname),
          ))
      ) {
        navigate(OTHER_MENU_PATH)
        return window.history.pushState(location.state, OTHER_MENU_PATH)
      }

      if (
        userTreatment &&
        userTreatment.state &&
        _.includes(TREATMENT_STATE_NOT_BACK, userTreatment.state)
      ) {
        if (user?.postal_number) {
          return
        }
        navigate(handleRedirectByState(userTreatment, user, {}))
        return window.history.pushState(
          location.state,
          handleRedirectByState(userTreatment, user, {}),
        )
      }

      if (
        userTreatment &&
        user.new_state !== USER_STATE.IDENTIFICATION_NG &&
        userTreatment &&
        userTreatment.state &&
        _.includes(
          [USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD],
          userTreatment.state,
        ) &&
        location.pathname !== PATH_CHAT
      ) {
        if (user.new_state === USER_STATE.IDENTITY_CHECK) {
          return navigate(COMPLETE_IDENTIFICATION)
        }
        return navigate(OTHER_MENU_PATH)
      }
    }
  }, [user?.user_treatments])

  useEffect(() => {
    if (user?.id) {
      const channelName = `private-user.${user?.id}`
      const socket = socketListen(channelName)
      return () => {
        socket.leaveChannel(channelName)
      }
    }
  }, [user?.id])

  const socketListen = (channelName) => {
    const echo = createEchoConnection()
    echo.channel(channelName).listen(SOCKET_EVENT.NOTIFICATION, ({ data }) => {
      setNumberOfUnreadNotice(data.notice_unread_count)
      pushNotificationToOldState(data)
    })
    return echo
  }

  const dispatch = useAppDispatch()

  const handleConfirm = () => {
    setIsVideoCallModal(false)
    const userTreatmentId = LocalStorage.getUserTreatmentId()
    dispatch(updateCallState({ id: userTreatmentId, state: 'accepted' }))
    return navigate(OTHER_MENU_PATH)
  }

  const handleDecline = async () => {
    setIsVideoCallModal(false)
    const userTreatmentId = LocalStorage.getUserTreatmentId()
    dispatch(updateCallState({ id: userTreatmentId, state: 'rejected' }))
  }

  const userTreatment = _.find(
    user?.user_treatments,
    (treatment) => treatment.id == LocalStorage.getUserTreatmentId(),
  )

  return (
    <>
      <Outlet />
      <VideoCallModal
        isOpen={isVideoCallModal}
        onSubmit={handleConfirm}
        onCancel={handleDecline}
        doctorRequestDate={userTreatment?.doctor_request_date || ''}
        menuName={userTreatment?.menu?.name}
      />
    </>
  )
}

export default AuthenticatedLayout
