import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Select, Skeleton } from 'antd'
import { useNavigate } from 'react-router-dom'
import qs from 'query-string'

import { useAppDispatch, useAppSelector } from 'src/store'
import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import SquareForm from './components/SquareForm'
import { usePayment } from './components/usePayment'
import { getPlanDetail, PostOrderInterface } from 'src/useCase/plan'
import ButtonCustom from 'src/components/form/Button'
import { PATH as REGULAR_PATH } from 'src/pages/regular'
import { PATH as AUTOMATIC_PLAN_PATH } from 'src/pages/automaticPlan'

import CardImg from 'src/assets/images/members/payment_card_info.png'
import { PLAN_TYPE } from 'src/constants/app'
import { getUserPlanType } from 'src/utils/helper'
import { getInfo } from 'src/useCase/user'
import LocalStorage from 'src/utils/LocalStorage'
import HeaderUnderlined from 'src/components/layout/Header/HeaderUnderlined'

const PaymentContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const planDetail = useAppSelector(
    (state) => state.domain.plans.planDetail.entity,
  )
  const user = useAppSelector((state) => state.ui.app.authInfo)
  const planType =
    LocalStorage.planType || getUserPlanType(user?.state_transitions || [])
  const planId = LocalStorage.activePlan

  const isSubscription = planType === PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION
  const isExam = planType === PLAN_TYPE.EXAMINATION
  const feedbackId = user?.last_doctor_feedback?.id

  const loading = useAppSelector(
    (state) => state.domain.video.videoAppointment.loading,
  )

  useEffect(() => {
    dispatch(
      getInfo({
        with: 'userTreatments,reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay,userTreatments.userTreatmentTransitions',
      }),
    )
  }, [])

  useEffect(() => {
    // if (user) {
    //   if (!planId) {
    //     dispatch(
    //       uiActions.onNotification({
    //         type: 'error',
    //         message: '決済する前にプランを選択してください。',
    //       }),
    //     )
    //     navigate(PATH.FLOW)
    //   } else {
    if (planId) {
      dispatch(
        getPlanDetail({
          plan_id: Number(planId),
          with: 'medicines',
        }),
      )
    }
    // }
    // }
  }, [planId])

  const payment = usePayment()
  const { onCharge } = payment

  const isZeroPayment =
    (planDetail && Number(planDetail?.price_first) === 0) || false
  const back = () => {
    if (isSubscription) {
      navigate(REGULAR_PATH.REGULAR_SUBSCRIPTION)
      return
    }
    if (isExam) {
      navigate(AUTOMATIC_PLAN_PATH.PROPOSAL)
      return
    } else {
      if (feedbackId) {
        navigate(`/karte-plan/prescription-proposal/${feedbackId}`)
        return
      }
    }
  }
  let orderParams: PostOrderInterface = {
    plan_id: Number(planId),
    plan_type: planType,
  }

  const params = qs.parse(location.search)
  if (
    params?.doctor_feedback_id &&
    params?.doctor_feedback_id !== 'undefined'
  ) {
    orderParams.doctor_feedback_id = Number(params?.doctor_feedback_id)
  }

  return (
    <Wrapper>
      <LPHeader />
      {loading ? (
        <Skeleton />
      ) : (
        <div
          className={`w-full justify-center mx-auto layout-webview register-form ${
            isSubscription ? 'mt-10' : 'mt-50'
          }`}
        >
          {isSubscription ? (
            <HeaderUnderlined name="定期購入手続き" />
          ) : (
            <StepsHeader
              step={10}
              stepActive={5}
              stepActiveName={'決済'}
              className="top-70"
            />
          )}
          <HeaderStyle>お支払い情報入力</HeaderStyle>
          {isZeroPayment && (
            <div className="fs-13 fw-300 mb-30 mt-20">
              初回は無料となりますが、次回以降の決済のためにお支払い方法をご入力ください。
            </div>
          )}
          <SelectMethod>
            <label>支払い方法</label>
            <Select
              className={'aga-select'}
              defaultValue="1"
              options={[
                {
                  value: '1',
                  label: 'クレジットカード',
                },
              ]}
            />
            <img className={'card-img'} src={CardImg} alt="" />
          </SelectMethod>
          <SquareForm
            onCharge={(token) => onCharge(token, planType, orderParams)}
            isZeroPayment={isZeroPayment}
            isSubscription={isSubscription}
          />

          {/* {!isSubscription && ( */}
          <p className="mt-20 cl-warning fs-13 lh-24">
            「お支払いをする」ボタンをクリック後、次の画面に切り替わった後、ブラウザの戻るボタンで戻った場合、再度決済処理が行われる可能性がございます。
            ボタンは1回のみクリックしてください。
          </p>
          <ButtonCustom
            className="btn-default btn-white mt-30"
            loading={false}
            disabled={false}
            onClick={back}
            htmlType="button"
            name="戻る"
          />
          {/* )} */}
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
const SelectMethod = styled.div`
  margin-top: 20px;

  .card-img {
    width: 100%;
    margin-top: 15px;
  }
`

export default PaymentContainer
