import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import qs from 'query-string'
import { find } from 'lodash'
import { PATH } from 'src/pages/video/Schedule'
import LPHeader from 'src/components/layout/Header/LPHeader'
import ButtonCustom from 'src/components/form/Button'
import MenuNameComponent from 'src/components/common/MenuNameComponent'
import { getReservationUserTreatment } from 'src/useCase/userTreatment'
import { useAppDispatch, useAppSelector } from 'src/store'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { KEY_FROM_DOCTOR } from 'src/App'
import LocalStorage from 'src/utils/LocalStorage'
import { getInfo } from 'src/useCase/user'

const Container: React.FC = () => {
  const user = useAppSelector((state) => state.ui.app.authInfo)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [queryParams] = useSearchParams()

  const userTreatment = useAppSelector(
    (state) => state.domain.myPage.detailUserTreatment.entity,
  )
  const [menuNameProp, setMenuNameProp] = useState<string | undefined>()

  useEffect(() => {
    const id =
      LocalStorage.getUserTreatmentId() ?? queryParams.get('userTreatmentId')
    if (id) {
      const params = {
        with: 'menu,lastReservation.doctor',
      }
      dispatch(getReservationUserTreatment({ id, params }))
    }
  }, [dispatch, queryParams])

  const location = useLocation()
  const params = qs.parse(location.search)

  useEffect(() => {
    dispatch(
      getInfo({
        with: 'userTreatments',
      }),
    )
    if (params.from === KEY_FROM_DOCTOR) {
      LocalStorage.setLocalStorage('doctor_request_link_data', {
        doctor_id: params.doctorId || params.doctor_id,
        user_treatment_id: params.userTreatmentId || params.user_treatment_id,
        from: params.from,
      })
      LocalStorage.setUserTreatmentId(
        params.userTreatmentId || params.user_treatment_id,
      )
    } else {
      LocalStorage.removeLocalStorage('doctor_request_link_data')
    }
  }, [])

  useEffect(() => {
    if (
      user &&
      user?.user_treatments?.length > 0 &&
      (params.userTreatmentId || params.user_treatment_id)
    ) {
      const userTreatmentId = params.userTreatmentId || params.user_treatment_id
      const userTreatment = find(
        user?.user_treatments,
        (treatment) => treatment.id == userTreatmentId,
      )

      if (userTreatment && userTreatment.menu) {
        const menuName = userTreatment.menu.name
        setMenuNameProp(menuName)
        LocalStorage.setMenuName(menuName)
      }
    }
  }, [user?.user_treatments, params])

  return (
    <Wrapper>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview">
        <StepsHeader
          step={10}
          stepActive={3}
          stepActiveName={'診察のご案内'}
          className="top-70"
        />
        <div className="video-interview">
          <div className="page-title mt-50 fs-20 fw-500">
            オンライン診察の案内
          </div>
          <div className="video-interview__content">
            <div className="fw-500 mb-15">
              {userTreatment?.last_reservation?.doctor?.name}です。
            </div>
            <div className="mb-15">
              問診のご協力ありがとうございました。
              <br />
              いただいた内容をもとに診察を実施いたしますので、次のページ診察日の予約へお進みください。
            </div>
            <MenuNameComponent className="mb-15" menuNameProp={menuNameProp} />
          </div>
          <ButtonCustom
            className="btn-default btn-chestnut-rose mt-70"
            loading={false}
            onClick={() => navigate(PATH)}
            name="診察の予約をする"
            showIconRight
          />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .video-interview {
    &__content {
      margin: 20px 0;
      font-size: 13px;
      font-weight: 300;
      background-color: #f1efe7;
      padding: 20px;

      span {
        color: var(--color-chestnut-rose);
        font-weight: 300;
      }
    }
  }
`

export default Container
