export const USER_STATE = {
  PATIENT_REGISTERED: 'patient_registered',
  REGISTERED_INTERVIEW: 'registered_interview',
  CAUTION_OK: 'caution_ok',
  CAUTION_NG: 'caution_ng',
  NO_NEED_INTERVIEW: 'no_need_interview',
  SUGGESTED_SHAMPOO: 'suggested_shampoo',
  CHOICE_INTERVIEW_METHOD: 'choice_interview_method',
  CHAT_INTERVIEW_SCHEDULED: 'chat_interview_scheduled',
  VIDEO_INTERVIEW_SCHEDULED: 'video_interview_scheduled',
  RESERVATION_CONFIRMATION: 'reservation_confirmation',
  CONFIRMED_RESERVATION: 'confirmed_reservation',
  VIDEO_INTERVIEW_DATE: 'video_interview_date',
  CONFIRMING_PRESCRIPTION: 'confirming_prescription',
  UN_APPROVE_PRESCRIPTION: 'un_approve_prescription',
  NOT_PAID: 'not_paid',
  IDENTITY_VERIFICATION_NOT_REGISTERED: 'identity_verification_not_registered',
  IDENTITY_CHECK: 'identity_check',
  IDENTIFICATION_OK: 'identification_ok',
  IDENTIFICATION_NG: 'identification_ng',
  SHAMPOO_NOT_PAID: 'shampoo_not_paid',
  SHAMPOO_REGULAR_PLAN: 'shampoo_regular_plan',
  DELIVERY_ADDRESS_REGISTERED: 'delivery_address_registered',
  PRESCRIPTION_OK: 'prescription_ok',
  SUBSCRIPTION_SUGGESTIONS: 'subscription_suggestions',
}

export const USER_GENDER = {
  MALE: 1,
  FEMALE: 0,
}
