import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { PATH as PATH_AUTH } from 'src/pages/auth'

import LPHeader from 'src/components/layout/Header/LPHeader'
import ButtonCustom from 'src/components/form/Button'
import StepsHeader from 'src/components/layout/Header/StepHeader'

const Container: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview">
        <StepsHeader
          step={10}
          stepActive={1}
          stepActiveName="個人情報を入力、会員登録"
          className="top-70"
        />
        <div className="registered">
          <div className="page-title mt-70 fs-20 fw-500">会員登録完了</div>
          <div className="registered__content">
            <p>ご登録ありがとうございます。</p>
            <br />
            <p>
              ログインすることで途中で別画面を表示した場合でもマイページより再開可能です。
            </p>
          </div>
          <ButtonCustom
            className="btn-default btn-chestnut-rose mt-70"
            loading={false}
            onClick={() => navigate(PATH_AUTH.MEDICAL_SPECIALTY)}
            name="診療科目選択へ"
            showIconRight
          />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .registered {
    &__content {
      margin: 20px 0;
      font-size: 13px;
      font-weight: 300;
      background-color: #f1efe7;
      padding: 20px;
    }
  }
`

export default Container
