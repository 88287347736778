import React, { useEffect } from 'react'
import styled from 'styled-components'

import StepsHeader from 'src/components/layout/Header/StepHeader'
import LPHeader from 'src/components/layout/Header/LPHeader'
import { PATH } from 'src/pages/myPage'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/store'
import { EMAIL_SUPPORT } from 'src/constants/env-constant'
import ButtonCustom from 'src/components/form/Button'
import { getInfo } from 'src/useCase/user'

const Container: React.FC = () => {
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.ui.app.authInfo)
  const dispatch = useAppDispatch()
  const handleMyPage = () => {
    navigate(PATH.OTHER_MENU)
  }

  useEffect(() => {
    dispatch(
      getInfo({
        with: 'userTreatments',
      }),
    )
  }, [])

  return (
    <Wrapper>
      <LPHeader />
      <StepsHeader
        step={10}
        stepActive={7}
        stepActiveName="本人確認書類アップロード"
        className="top-70"
      />
      <div className="w-full justify-center mx-auto layout-webview mt-70">
        <h2 className="mt-5 text-xl font-bold page-title">
          本人確認書類提出完了
        </h2>
        <div className="register-success-content">
          <p>本人確認書類のご提出ありがとうございます。</p>
          <br />
          {!user?.line_id ? (
            <>
              <p>
                担当による確認が完了次第、メールにて薬剤配送のご案内をいたしますので、今しばらくお待ちください。
              </p>
              <br />
              <p>
                ご不明点がございましたら以下のメールアドレスまでお問い合わせください。
              </p>
              <br />
              <p>
                Mail：
                <a href={`mailto:${EMAIL_SUPPORT}`} className="reset-link">
                  {EMAIL_SUPPORT}
                </a>{' '}
              </p>
            </>
          ) : (
            <>
              <p>
                担当による確認が完了次第、LINEメッセージにて薬剤配送のご案内をいたしますので、今しばらくお待ちください。
              </p>
            </>
          )}
        </div>
        <div className="pb-4 mt-10">
          <ButtonCustom
            className="btn-default btn-gurkha "
            loading={false}
            onClick={handleMyPage}
            name="マイページ"
            htmlType="button"
            showIconRight
          />
        </div>
        {/* {!user?.line_id && <LineBox />} */}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .register-success-content {
    padding: 20px;
    margin: 20px 0 40px;
    background-color: #f1efe7;
    font-size: 13px;
    font-weight: 400;
  }
  .reset-link {
    color: #1e4c82;
  }
  p {
    margin-bottom: 0 !important;
  }
`

export default Container
