import Questions, { PATH as QUESTIONS } from './Question'
import UploadImage, { PATH as UPLOAD_IMAGE } from './UploadImage'
import Precaution, { PATH as PRECAUTION } from './Precaution'
import Proposal, { PATH as PROPOSAL } from './Proposal'
import Identification, { PATH as IDENTIFICATION } from './Identification'
import CompleteIdentification, {
  PATH as COMPLETE_IDENTIFICATION,
} from './CompleteIdentification'
import AddressEntry, { PATH as ADDRESS_ENTRY } from './AddressEntry'
import SMSAuth, { PATH as SMS_AUTH } from './SMSAuth'
import SuggestShampoo, {
  PATH as SUGGEST_SUBSCRIPTION,
} from './SuggestSubscription'
import MedicalExamination, {
  PATH as MEDICAL_EXAMINATION,
} from './MedicalExamination'
import PlanDetail, { PATH as PLAN_DETAIL } from './PlanDetail'

export default {
  Questions,
  UploadImage,
  Precaution,
  Proposal,
  PlanDetail,
  AddressEntry,
  SMSAuth,
  Identification,
  CompleteIdentification,
  SuggestShampoo,
  MedicalExamination,
}
export const PATH = {
  QUESTIONS,
  UPLOAD_IMAGE,
  PRECAUTION,
  PROPOSAL,
  PLAN_DETAIL,
  ADDRESS_ENTRY,
  SMS_AUTH,
  IDENTIFICATION,
  COMPLETE_IDENTIFICATION,
  SUGGEST_SUBSCRIPTION,
  MEDICAL_EXAMINATION,
}
