import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Skeleton } from 'antd'

import { useAppDispatch, useAppSelector } from 'src/store'
import { getWorkingDates } from 'src/useCase/video'
import LPHeader from 'src/components/layout/Header/LPHeader'
import Form from './Form'
import HeaderUnderlined from 'src/components/layout/Header/HeaderUnderlined'
import LocalStorage from 'src/utils/LocalStorage'

const Container: React.FC = () => {
  const dispatch = useAppDispatch()
  const workingDates = useAppSelector((state) => state.domain.video.workingDate)
  useEffect(() => {
    let params = {}
    const userTreatmentId = LocalStorage.getUserTreatmentId()
    if (LocalStorage.getLocalStorage('doctor_request_link_data')) {
      params = LocalStorage.getLocalStorage('doctor_request_link_data')
    } else if (userTreatmentId) {
      params = { user_treatment_id: userTreatmentId }
    }
    dispatch(getWorkingDates({ params }))
  }, [])

  return (
    <Wrapper>
      <LPHeader />
      {workingDates.loading ? (
        <Skeleton />
      ) : (
        <div className="w-full justify-center mx-auto layout-webview">
          <HeaderUnderlined name="診察のご案内" />
          <div className="mt-10">
            <div className="page-title fs-20 fw-500 mt-30">
              オンライン診察予約
            </div>
            <div className="page-sub-title mt-30">
              担当医が対応可能な日時が選択できます。
              <br />
              ご都合の良い日時を選択して予約してください。
            </div>
            <Form />
          </div>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker {
    background-color: var(--color-spring-wood);
    border-radius: 8px;
    border: 1px solid var(--color-spring-wood);
  }

  .ant-select-selection-placeholder {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`

export default Container
