import React from 'react'

import ListTreatmentCancelContainer from 'src/containers/ListTreatmentCancel'

const ListTreatmentCancelPage: React.FC = () => {
  return <ListTreatmentCancelContainer />
}

export const PATH = '/list-treatment-cancel' as const
export default ListTreatmentCancelPage
