import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import moment from 'moment'

import { HOUR_MIN, HOUR_MIN_SECOND } from 'src/constants/app'
import { roundingDownMinutes, roundingUpMinutes } from 'src/utils/helper'
import { PATH } from 'src/pages/video'
import { PATH as OTHER_MENU_PATH } from 'src/pages/myPage/OtherMenu'
import videoDoctorFreeTime from 'src/store/domain/video/doctorFreeTime'
import videoWorkingDate from 'src/store/domain/video/workingDate'
import videoRequest from 'src/api/requests/video'
import reservationReq from 'src/api/requests/reservation'
import videoAppointmentAction from 'src/store/domain/video/videoAppointment'
import history from 'src/libs/history'
import uiActions from 'src/store/ui/notification'
import LocalStorage from 'src/utils/LocalStorage'

export interface IBusyTimeParam {
  date: string
  doctor_id?: string
  user_treatment_id?: string
}

export interface paramsBusyTime {
  params: IBusyTimeParam
}

export interface paramsWorkingDates {
  params: {}
}

export interface GetVideoAppointment {
  token: string | undefined
}

export interface dateSchedule {
  date: string
}

export const busyTime = createAsyncThunk<any, paramsBusyTime>(
  'get_busy_time',
  async ({ params }, { dispatch }) => {
    try {
      dispatch(videoDoctorFreeTime.updateLoading(true))
      const res = await videoRequest.busyTime(params)
      if (res.data) {
        let optionTimes: Response.optionDoctorFreeTime[] = []
        res.data &&
          _.forEach(res.data.doctor_shift_with_date, (item) => {
            let startTime = roundingUpMinutes(
              moment(item.start_time, HOUR_MIN_SECOND),
            )
            let endTime = roundingDownMinutes(
              moment(item.end_time, HOUR_MIN_SECOND),
            )
            while (startTime < endTime) {
              const timeFrom = startTime.format(HOUR_MIN)
              const timeTo = startTime.add(30, 'minutes').format(HOUR_MIN)
              if (
                !_.includes(
                  _.map(
                    res.data.doctor_busy_time_with_date,
                    'reservation_time',
                  ),
                  timeFrom,
                )
              ) {
                optionTimes.push({
                  value: timeFrom,
                  label: `${timeFrom} - ${timeTo}`,
                })
              }
            }
          })
        dispatch(videoDoctorFreeTime.updateEntity(optionTimes))
      }
      dispatch(videoDoctorFreeTime.updateLoading(false))
    } catch (error) {
      dispatch(videoDoctorFreeTime.updateLoading(false))
    }
  },
)

export const setBusyTime = createAsyncThunk<any, any>(
  'reset_busy_time',
  async (data, { dispatch }) => {
    dispatch(videoDoctorFreeTime.updateEntity(data))
  },
)

export const getWorkingDates = createAsyncThunk<any, paramsWorkingDates>(
  'get_working_dates',
  async ({ params }, { dispatch }) => {
    try {
      dispatch(videoWorkingDate.updateLoading(true))
      const res = await videoRequest.getWorkingDates(params)
      if (res.data) {
        dispatch(videoWorkingDate.updateEntity(res.data.data))
      }
      dispatch(videoWorkingDate.updateLoading(false))
    } catch (error) {
      let message = _.get(error, 'response.data.message', '')
      message = message.includes('user_treatment_has_not_call_request')
        ? 'すでに予約が入っています。'
        : message
      dispatch(
        uiActions.onNotification({
          type: 'error',
          message,
        }),
      )
      history.push(OTHER_MENU_PATH)
      dispatch(videoWorkingDate.updateLoading(false))
    }
  },
)

export const videoAppointment = createAsyncThunk<any, GetVideoAppointment>(
  'get_video_appointment',
  async ({ token }, { dispatch }) => {
    try {
      dispatch(videoAppointmentAction.updateLoading(true))
      const res = await reservationReq.getVideoAppointment(token)
      if (res.data) {
        dispatch(videoAppointmentAction.updateEntity(res.data))
      }
      const agoraOutcome = await reservationReq.getAgoraToken()
      dispatch(
        videoAppointmentAction.updateAgoraChannel(agoraOutcome.data.data),
      )
      dispatch(videoAppointmentAction.updateLoading(false))
    } catch (error) {
      const msg = _.get(error, 'response.data.message', '')
      dispatch(videoAppointmentAction.updateLoading(false))
      dispatch(videoAppointmentAction.updateErrorMsg(msg))
      history.push(PATH.NOT_ALLOWED)
    }
  },
)

export const endVideoCall = createAsyncThunk<any, any>(
  'end_video_call',
  async (data: any, { dispatch }) => {
    try {
      await reservationReq.endVideoCall(data)
      history.push(PATH.THANK)
      LocalStorage.removeLocalStorage('show_phone')
    } catch (error) {
      const msg = _.get(error, 'response.data.message', '')
      dispatch(videoAppointmentAction.updateLoading(false))
      dispatch(videoAppointmentAction.updateErrorMsg(msg))
      history.push(PATH.NOT_ALLOWED)
    }
  },
)

export const createReservationDate = async (data: dateSchedule) => {
  try {
    const userTreatmentId = LocalStorage.getUserTreatmentId()

    await videoRequest.createReservationDate({
      ...data,
      ...{ user_treatment_id: userTreatmentId },
    })
    LocalStorage.removeLocalStorage('doctor_request_link_data')
    return true
  } catch (error) {
    return false
  }
}
