import React, { useEffect, useMemo, useState } from 'react'
import { Form, Skeleton } from 'antd'
import { filter, find, get, isArray, some } from 'lodash'

import { getQuestionsByMenuId } from 'src/useCase/question'
import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { Wrapper } from 'src/components/layout/Wrapper'
import ButtonCustom from 'src/components/form/Button'
import LPHeader from 'src/components/layout/Header/LPHeader'
import { useAppDispatch, useAppSelector } from 'src/store'
import { QuestionItem } from './styled'
import { useQuestionForm } from './QuestionForm'
import { getInfo } from 'src/useCase/user'
import { useParams } from 'react-router-dom'
import LocalStorage from 'src/utils/LocalStorage'
import MenuNameComponent from 'src/components/common/MenuNameComponent'

const QuestionContainer = (props: any) => {
  const dispatch = useAppDispatch()
  const paramsUrl = useParams()

  const { form, onSubmit } = useQuestionForm()
  const { handleSubmit, setValue, formState, setError } = form

  const [submitData, setSubmitData] = useState({
    answers: [],
    reservation_id: '',
    menu_id: 0,
  })

  const questionList = useAppSelector(
    (state) => state.domain.automaticPlan.questions.entity,
  )
  const isLoadingQuestionList =
    useAppSelector((state) => state.domain.automaticPlan.questions.loading) ||
    false
  const questionObject =
    questionList &&
    questionList.length > 0 &&
    questionList.reduce((a, v, key) => ({ ...a, [`${key + 1}`]: v }), {})

  const userInfo = useAppSelector((state) => state.ui.app.authInfo)
  const userTreatment = useMemo(() => {
    return find(
      userInfo?.user_treatments,
      (userTreatment) => userTreatment.menu_id == paramsUrl?.id,
    )
  }, [userInfo])
  const reservation =
    find(
      userTreatment?.reservations,
      (reservation) => reservation?.answers?.length === 0,
    ) || userTreatment?.reservations[userTreatment?.reservations?.length - 1]

  useEffect(() => {
    dispatch(getQuestionsByMenuId({ menu_id: paramsUrl?.id || '' }))
  }, [])

  useEffect(() => {
    LocalStorage.removePrecaution()
    dispatch(
      getInfo({
        with: 'userTreatments,userTreatments.reservations,userTreatments.reservations.answers,userTreatments.userTreatmentTransitions',
      }),
    )
  }, [])

  const handleChange = (name: any, type: string, questionAnswer: any) => {
    if (type === 'checkbox') {
      if (
        questionAnswer &&
        !some(
          form.watch(name),
          (checkboxValue) => checkboxValue.value === questionAnswer.value,
        )
      ) {
        const formValue = form.watch(name)
        if (formValue) {
          setValue(name, [...formValue, questionAnswer])
          setError(name, {})
        } else {
          setValue(name, [questionAnswer])
          setError(name, {})
        }
      } else {
        setValue(name, [
          ...filter(
            form.watch(name),
            (data) => data.value !== questionAnswer.value,
          ),
        ])
        setError(name, {})
      }
    } else {
      setValue(name, questionAnswer)
      setError(name, {})
    }
    const data = form.getValues()

    let answerData: any = []

    data &&
      Object.entries(data).map((formValue) => {
        const data =
          questionObject &&
          find(
            questionObject,
            (data) => get(data, 'id') === Number(formValue[0]),
          )
        const questionData = questionObject ? data : { type: '', id: '' }
        const value = get(formValue, '1', null)

        if (questionData) {
          const questionType = questionData.type

          let valueInput = {
            is_call_video: false,
            value: value,
            count_number: get(questionData, 'options.count_number', 0),
          }
          let nextAnswer: any = {
            answer:
              questionType === 'radio'
                ? [value]
                : questionType == 'checkbox'
                ? value
                : [valueInput],
            question_id: questionData.id,
            question_type: questionData.type,
          }

          answerData = [...answerData, nextAnswer]
        }
      })

    setSubmitData({
      answers: answerData,
      reservation_id: reservation.id,
      menu_id: Number(paramsUrl?.id),
    })
    // dispatch(
    //   draffAnswer({
    //     answers: answerData,
    //     reservation_id: reservationId,
    //   }),
    // )
    return
  }

  // const handleBack = () => {
  //   history.push(PATH_REGISTER)
  // }

  if (isLoadingQuestionList) {
    return <Skeleton />
  }

  return (
    <>
      <LPHeader />
      <Wrapper className="w-full justify-center mx-auto">
        <StepsHeader
          step={10}
          stepActive={2}
          stepActiveName={'問診・注意事項確認'}
          className="top-70"
        />
        <div className="pl-20">
          <HeaderStyle>問診票</HeaderStyle>
          <MenuNameComponent className="mb-25" />
        </div>

        {isLoadingQuestionList ? (
          <div className="mx--20">
            <Skeleton />
          </div>
        ) : (
          <Form
            onFinish={() => handleSubmit(onSubmit(submitData))}
            className="pb-20"
          >
            {questionList &&
              questionList.length > 0 &&
              questionList.map((question, index) => {
                const qName: any = `${question.id}`
                // const qNameBefore: any = `${index}`
                // const valueBefore = form.watch(qNameBefore)
                // const isDisabledText =
                // valueBefore && valueBefore.value === 'なし'
                // const isDisabledCheckbox =
                // qName === '4' && valueBefore && valueBefore.value === 'なし'
                const questionName =
                  question.question && question.question.split('\\n')
                let maxLengthOption = 0
                question.options &&
                  isArray(question.options) &&
                  question.options.map((option) => {
                    if (option.value.length > Number(maxLengthOption)) {
                      maxLengthOption = option.value.length
                    }
                    return option
                  })

                if (maxLengthOption < 30) {
                  maxLengthOption = 30
                }

                return (
                  <QuestionItem key={question.id}>
                    <div className="q-title">
                      Q{index + 1}{' '}
                      <span className="pre-wrap">
                        {questionName &&
                          questionName.map(function (item, idx) {
                            return (
                              <span key={idx}>
                                {item}
                                <br />
                              </span>
                            )
                          })}
                      </span>
                    </div>
                    {question.type === 'checkbox' && (
                      <>
                        <div className="q-wrap">
                          {question.options.length > 0 &&
                            question.options.map((item, index) => {
                              const isChecked =
                                form.watch(qName) &&
                                some(
                                  form.watch(qName),
                                  (checkboxValue) =>
                                    checkboxValue.value === item.value,
                                )

                              return (
                                <label
                                  className="label-q c-pointer"
                                  // {`label-q c-pointer ${
                                  //   isDisabledCheckbox ? 'inactive' : ''
                                  // }`}
                                  key={index}
                                >
                                  <input
                                    type="checkbox"
                                    placeholder="January"
                                    name={qName}
                                    className="btn-q mx-3"
                                    // {`btn-q mx-3 ${
                                    //   isDisabledCheckbox ? 'inactive' : ''
                                    // }`}
                                    onChange={(event) =>
                                      handleChange(
                                        event.target.name,
                                        'checkbox',
                                        item,
                                      )
                                    }
                                    checked={isChecked}
                                    value={item.value}
                                    id={`${qName}-${item.value}-${index}`}
                                  />
                                  <span
                                    // className={`${
                                    //   isDisabledCheckbox ? 'inactive' : ''
                                    // }`}
                                    style={{
                                      padding: `${
                                        maxLengthOption / 2.4 + 10
                                      }px 0`,
                                    }}
                                  >
                                    {item.value}
                                  </span>
                                </label>
                              )
                            })}
                        </div>
                        {
                          // !isDisabledCheckbox &&
                          form.getFieldState(qName) &&
                            form.getFieldState(qName).error?.message && (
                              <div className="error-text">
                                選択してください。
                              </div>
                            )
                        }
                      </>
                    )}
                    {question.type === 'radio' && (
                      <>
                        <div className="q-wrap">
                          {question.options.length > 0 &&
                            question.options.map((item, index) => {
                              return (
                                <label
                                  className="label-q c-pointer"
                                  key={index}
                                >
                                  <input
                                    type="radio"
                                    placeholder="January"
                                    name={qName}
                                    className="btn-q mx-3"
                                    checked={
                                      form.watch(qName) &&
                                      form.watch(qName).value === item.value
                                    }
                                    onChange={(event) =>
                                      handleChange(
                                        event.target.name,
                                        'radio',
                                        item,
                                      )
                                    }
                                    value={item.value}
                                    id={`${qName}-${item.value}`}
                                  />
                                  <span
                                    style={{
                                      padding: `${
                                        maxLengthOption / 2.4 + 10
                                      }px 0`,
                                    }}
                                  >
                                    {item.value}
                                  </span>
                                </label>
                              )
                            })}
                        </div>
                        {form.getFieldState(qName) &&
                          form.getFieldState(qName).error?.message && (
                            <div className="error-text">選択してください。</div>
                          )}
                      </>
                    )}
                    <div className="px--20 mb-20">
                      {question.type === 'textarea' && (
                        <>
                          <textarea
                            // {...register(qName)}
                            name={qName}
                            className="aga-input question-input mb-10 px-10 aga-input-area"
                            // {`aga-input question-input mb-10 px-10 ${
                            //   isDisabledText ? 'inactive' : ''
                            // }`}
                            placeholder={
                              question.placeholder || '入力してください'
                            }
                            // disabled={isDisabledText}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                'textarea',
                                event.target.value,
                              )
                            }
                          />
                          {
                            // !isDisabledText &&
                            form.getFieldState(qName) &&
                              form.getFieldState(qName).error?.message && (
                                <div className="error-text mt-e-20 px--0">
                                  入力してください。
                                </div>
                              )
                          }
                        </>
                      )}
                    </div>
                    <div className="px--20 mb-20">
                      {question.type === 'input' && (
                        <>
                          <input
                            // {...register(qName)}
                            name={qName}
                            className="aga-input question-input mb-10 px-10"
                            // {`aga-input question-input mb-10 px-10 ${
                            //   isDisabledText ? 'inactive' : ''
                            // }`}
                            placeholder={
                              question.placeholder || '入力してください'
                            }
                            // disabled={isDisabledText}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                'input',
                                event.target.value,
                              )
                            }
                          />
                          {
                            // !isDisabledText &&
                            form.getFieldState(qName) &&
                              form.getFieldState(qName).error?.message && (
                                <div className="error-text mt-e-20 px--0">
                                  入力してください。
                                </div>
                              )
                          }
                        </>
                      )}
                    </div>
                  </QuestionItem>
                )
              })}

            <div className="p-20">
              <ButtonCustom
                className="btn-default btn-chestnut-rose"
                loading={formState.isSubmitting}
                htmlType="submit"
                name="次へ進む"
                showIconRight
              />
              {/* <ButtonCustom
                className="btn-default btn-white mt-20"
                loading={false}
                htmlType="button"
                onClick={handleBack}
                name="戻る"
              /> */}
            </div>
          </Form>
        )}
      </Wrapper>
    </>
  )
}

export default QuestionContainer
