import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { Avatar, Skeleton } from 'antd'
import { useAppDispatch, useAppSelector } from 'src/store'
import { videoAppointment } from 'src/useCase/video'
import dayjs from 'dayjs'
import LPHeader from 'src/components/layout/Header/LPHeader'
import { PATH } from 'src/pages/video'
import ButtonCustom from 'src/components/form/Button'
import LocalStorage from 'src/utils/LocalStorage'

const IncomingContainer: React.FC = () => {
  const { token } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isWaitingToRoom, setIsWaitingToRoom] = useState(false)
  useEffect(() => {
    dispatch(videoAppointment({ token }))
    LocalStorage.setVideoToken(token)
  }, [token])

  const videoAppointmentEntity = useAppSelector(
    (state) => state.domain.video.videoAppointment.entity,
  )
  const loading = useAppSelector(
    (state) => state.domain.video.videoAppointment.loading,
  )

  const calling = () => {
    const now = dayjs()
    const isAfterNow = now.isAfter(
      dayjs(videoAppointmentEntity?.reservation_date),
    )
    if (isAfterNow) {
      navigate(PATH.CALLING)
    } else {
      setIsWaitingToRoom(true)
    }
  }

  return (
    <Wrapper>
      <LPHeader />
      {loading ? (
        <Skeleton />
      ) : (
        <div className="video-interview fixed-wrapper">
          <div className="page-title">オンライン診察の案内</div>
          <div className="video-interview__content doctor-info">
            <div className="avatar d-flex">
              <Avatar
                size={100}
                src={videoAppointmentEntity?.doctor.avatar_url}
              />
            </div>
            <div className="info">
              <p>
                担当医師：
                <span>{videoAppointmentEntity?.doctor.name}</span>
              </p>
              <p>
                診療科目：
                <span>
                  {
                    videoAppointmentEntity?.reservation?.user_treatment.menu
                      .name
                  }
                </span>
              </p>
              <p>
                予約日時：
                <span>
                  {dayjs(videoAppointmentEntity?.reservation_date).format(
                    'YYYY年MM月DD日 HH:mm〜',
                  )}
                </span>
              </p>
            </div>
            <div className="hint">
              <p>
                ご予約時間になりましたら、通話開始ボタンをクリックしてください。
              </p>
              <p>
                時間外の場合は、通話不可となっておりますので、ご注意ください。
              </p>
              <p className="warning mt-10">
                ※ビデオ通話のページでマイクとカメラの許可が表示されますので、許可をタップしてください。
                <br />
                許可されない場合、ビデオ通話が開始できないのでご注意ください。
              </p>
              <p className="warning mt-10">
                またスマートフォンは縦にしてご利用ください。
              </p>
            </div>
          </div>
          <ButtonCustom
            className="btn-default mt-5 btn-submit btn-gurkha"
            loading={false}
            onClick={() => calling()}
            name="ビデオ通話開始"
          />
          {isWaitingToRoom && (
            <p className="text-warning text-align-last-center mt-20">
              お時間になるまでしばらくおまちください
            </p>
          )}
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .video-interview {
    padding: 0 20px;

    .page-title {
      font-size: 20px;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    &-submit {
      margin-top: 20px;
      width: 100%;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.13em;
      background: #998800;
      margin-bottom: 55px;
      height: 60px;

      &.disabled {
        background: #f1efe7;
      }
    }

    .doctor-info {
      padding: 20px;
      background-color: #f1efe7;

      .avatar {
        display: flex;
        justify-content: center;
      }

      .info {
        border-bottom: 1px solid #cfc392;
        padding-bottom: 20px;
        margin: 20px 0;
        font-size: 15px;
      }

      .hint {
        margin-top: 20px;
        font-size: 13px;

        .warning {
          color: var(--color-warning);
        }
      }
    }
  }
`

export default IncomingContainer
