import React from 'react'
import Modal from './BasicModal'

import agaPopupIcon from 'src/assets/icons/sheep.jpeg'
import { TopIcon } from './ModalStyle'

type Props = {
  isOpen: boolean
  title?: string
  onSubmit: (submit: boolean) => void
  labelSubmit?: string
  labelCancel?: string
  classSubmit?: string
}

const BirthDateAGenderModal: React.FC<Props> = (props) => {
  // const handleSubmit = () => {
  //   props.onSubmit(true)
  // }

  const handleCancel = () => {
    props.onSubmit(false)
  }

  return (
    <Modal isOpen={props.isOpen} onClose={handleCancel}>
      <div className="d-flex justify-content-center">
        <TopIcon>
          <img className="img" src={agaPopupIcon} alt="" />
        </TopIcon>
      </div>
      <div className="modal-content not-padding-p">
        <div className="modal-header fs-18 mt-2 mb-2">申し訳ありません。</div>
        <p>
          <b className="cl-primary">18歳未満の方、男性以外の方</b>は
        </p>
        <p> 本サービスをご利用いただけません。</p>
        <p>
          <b className="cl-primary">今後、女性向けサービスの開始を予定</b>
          しており
        </p>
        <p>ますので今しばらくおまちください。</p>
      </div>
    </Modal>
  )
}

export default BirthDateAGenderModal
