import HTTPRequest from 'src/api'
import { FormValues } from 'src/containers/Video/Schedule/Form/useSchedule'
import { dateSchedule } from 'src/useCase/video'

const apiPath = 'api/users'

class Video extends HTTPRequest {
  busyTime(params: FormValues) {
    return this.get<Response.BusyTime>(`${apiPath}/reservations/select-date`, {
      params,
    })
  }

  getWorkingDates(params = {}) {
    return this.get<Response.WorkingDates>(`${apiPath}/doctor-shift`, {
      params,
    })
  }

  createReservationDate(data: dateSchedule) {
    return this.patch(`${apiPath}/reservations/reservation-date`, data)
  }
}

export default new Video()
