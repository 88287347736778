import React from 'react'

import { PATH as AUTOMATIC_PLAN_PATH } from 'src/pages/automaticPlan'
import AuthenticatedLayout from '../components/AuthenticatedLayout'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'

const AuthRoute: IRouter[] = [
  {
    element: <AuthenticatedLayout />,
    children: [
      {
        path: AUTOMATIC_PLAN_PATH.QUESTIONS,
        element: loadable(() => import('src/pages/automaticPlan/Question')),
      },
      {
        path: AUTOMATIC_PLAN_PATH.UPLOAD_IMAGE,
        element: loadable(() => import('src/pages/automaticPlan/UploadImage')),
      },
      {
        path: AUTOMATIC_PLAN_PATH.PRECAUTION,
        element: loadable(() => import('src/pages/automaticPlan/Precaution')),
      },
      {
        path: AUTOMATIC_PLAN_PATH.PROPOSAL,
        element: loadable(() => import('src/pages/automaticPlan/Proposal')),
      },
      {
        path: AUTOMATIC_PLAN_PATH.PLAN_DETAIL,
        element: loadable(() => import('src/pages/automaticPlan/PlanDetail')),
      },
      {
        path: AUTOMATIC_PLAN_PATH.ADDRESS_ENTRY,
        element: loadable(() => import('src/pages/automaticPlan/AddressEntry')),
      },
      {
        path: AUTOMATIC_PLAN_PATH.SMS_AUTH,
        element: loadable(() => import('src/pages/automaticPlan/SMSAuth')),
      },
      {
        path: AUTOMATIC_PLAN_PATH.IDENTIFICATION,
        element: loadable(
          () => import('src/pages/automaticPlan/Identification'),
        ),
      },
      {
        path: AUTOMATIC_PLAN_PATH.COMPLETE_IDENTIFICATION,
        element: loadable(
          () => import('src/pages/automaticPlan/CompleteIdentification'),
        ),
      },
      {
        path: AUTOMATIC_PLAN_PATH.SUGGEST_SUBSCRIPTION,
        element: loadable(
          () => import('src/pages/automaticPlan/SuggestSubscription'),
        ),
      },
      {
        path: AUTOMATIC_PLAN_PATH.MEDICAL_EXAMINATION,
        element: loadable(
          () => import('src/pages/automaticPlan/MedicalExamination'),
        ),
      },
    ],
  },
]

export default AuthRoute
