import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { PATH as PATH_AUTH } from 'src/pages/auth'
// store
import { useAppDispatch, useAppSelector } from 'src/store'

// enums
import { USER_TREATMENT_STATE } from 'src/enum/userTreatmentState'

// components
import LPHeader from 'src/components/layout/Header/LPHeader'

// use case
import { getUserTreatmentList } from 'src/useCase/userTreatment'

export type paramsType = {
  with: string
  state?: string
}
const Container: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const userTreatments = useAppSelector(
    (state) => state.domain.myPage.userTreatment.entities,
  )

  const getUserTreatments = () => {
    const state = [
      USER_TREATMENT_STATE.CANCELED,
      USER_TREATMENT_STATE.DOCTOR_CANCEL_PRESCRIPTION,
    ]

    let params: paramsType = {
      with: 'menu,reservations,lastDoctorFeedback,lastOrder.plan,userTreatmentTransitions',
      state: state.join(','),
    }

    dispatch(getUserTreatmentList(params))
  }

  useEffect(() => {
    getUserTreatments()
  }, [])
  return (
    <Wrapper>
      <LPHeader />
      <div className="other-menu">
        <div className="w-full justify-center mt-20 mx-auto layout-webview">
          {userTreatments &&
            userTreatments.map((userTreatment, index) => {
              return (
                <div key={index} className="menu-item w-full">
                  <span className="d-flex justify-content-between pb-10 w-full">
                    <div className="d-flex flex-column fw-bold w-full">
                      <span className="menu-name cl-black">
                        {userTreatment?.menu?.name ||
                          userTreatment.last_order?.plan?.name}
                      </span>
                      <div
                        onClick={() =>
                          navigate(PATH_AUTH.MEDICAL_SPECIALTY, {
                            state: { menuId: userTreatment?.menu_id },
                          })
                        }
                        className="text-link text-right"
                      >
                        キャンセルした診療科目を再購入される方はコチラ
                      </div>
                    </div>
                  </span>
                </div>
              )
            })}
        </div>
        <div className="d-flex justify-center mt-80">
          <div className="d-flex justify-center mx-auto">
            {userTreatments.length === 0 && (
              <p>キャンセルした診療項目がありません。</p>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #f1efe7;
  min-height: 100vh;
  .other-menu {
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: #000000;
      margin-top: 10px;
    }
    .menu-name {
      font-size: 15px;
      line-height: 32px;
    }
  }

  .menu-item {
    color: #000000;
    margin-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
    line-height: 40px;
  }

  .visibility-hidden {
    visibility: hidden;
  }

  .mt-80 {
    margin-top: 80px;
  }

  .btn-line {
    border: navajowhite;
    text-decoration: underline;
    cursor: pointer;
  }

  .text-title {
    font-weight: bold;
    margin: 20px;
  }

  .w-full {
    width: 100%;
  }

  .text-link {
    color: hsl(209, 100%, 37.5%);
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
  }
`

export default Container
