import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Skeleton } from 'antd'
import moment from 'moment'
import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

import LPHeader from 'src/components/layout/Header/LPHeader'
import MenuHeader from 'src/components/layout/Header/MenuHeader'
import { useAppDispatch, useAppSelector } from 'src/store'
import {
  getListNotification,
  getDetailNotification,
  decrementUnreadNotice,
} from 'src/useCase/notification'
import { JP_DATE_FORMAT, PAGINATE } from 'src/constants/app'
import { NotificationInterface } from 'src/interfaces/notification'
import CommonModal from 'src/components/Modal/CommonModal'
import ButtonCustom from 'src/components/form/Button'

const Container: React.FC = () => {
  const [data, setData] = useState<NotificationInterface[]>([])
  const [openDetail, setOpenDetail] = useState<boolean>(false)
  const [page, setPage] = useState<number>(PAGINATE.DEFAULT_PAGE)
  const [detail, setDetail] = useState<NotificationInterface | null>(null)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const notications = useAppSelector(
    (state) => state.domain.notification.listNotification.entity,
  )

  useEffect(() => {
    setData([])
    dispatch(
      getListNotification({
        page: PAGINATE.DEFAULT_PAGE,
        per_page: PAGINATE.PER_PAGE,
      }),
    )
  }, [location])

  useMemo(() => {
    let listNotice = _.get(notications, 'data', [])
    if (listNotice.length === 1 && listNotice[0].is_realtime_notice) {
      return setData([...listNotice, ...data])
    }
    setData([...data, ...listNotice])
  }, [notications])

  const onLoadMore = () => {
    const newPage = page + 1
    setPage(newPage)
    dispatch(
      getListNotification({ page: newPage, per_page: PAGINATE.PER_PAGE }),
    )
  }

  const getDetail = (notification) => {
    setOpenDetail(true)
    setDetail(notification)
    if (notification.is_unread) {
      dispatch(getDetailNotification(notification.id))
      const newData = data.map((item) => {
        if (item.id == notification.id) {
          return { ...item, ...{ is_unread: false } }
        }

        return item
      })
      setData(newData)

      decrementUnreadNotice(1)
    }
  }

  return (
    <Wrapper>
      <LPHeader />
      <MenuHeader name="お知らせ" />

      <div className="list-notification w-full justify-center mx-auto mt-15">
        <InfiniteScroll
          dataLength={data.length}
          next={onLoadMore}
          height="75vh"
          hasMore={!!notications?.next_page_url}
          loader={<Skeleton className="p-15" />}
        >
          <div className="layout-webview">
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <div
                    className="message"
                    key={index}
                    onClick={() => getDetail(item)}
                  >
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{ __html: item?.message || '' }}
                    ></div>
                    <div className="d-flex justify-content-between">
                      <span className="cl-dusty-gray">
                        {moment(item.created_at).format(JP_DATE_FORMAT)}
                      </span>
                      <div>
                        {item.is_unread && <span className="un-read">NEW</span>}
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </InfiniteScroll>
      </div>

      <div className="pl-15 pr-15 mb-20">
        <ButtonCustom
          className="btn-default btn-gurkha"
          loading={false}
          onClick={() => navigate(-1)}
          htmlType="button"
          name="戻る"
          showIconRight
        />
      </div>
      <CommonModal isOpen={openDetail} onClose={() => setOpenDetail(false)}>
        <div
          className="mt-30 p-15"
          dangerouslySetInnerHTML={{ __html: detail?.message || '' }}
        ></div>
      </CommonModal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .list-notification {
    .message {
      border-bottom: 1px solid var(--color-alto);
      padding: 10px 0;

      .content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .un-read {
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 16px;
        color: var(--color-white);
        background-color: var(--color-limed-ash);
        padding: 3px;
        border-radius: 3px;
      }
    }
  }
`

export default Container
