import React, { Fragment, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { legalNotices } from './constant'

const LegalNoticeContainer: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const back = () => {
    navigate(-1)
  }
  return (
    <Template>
      <div className="policy">
        <Wrapper>
          <h2 className="policy-top">特定商取引法に基づく表記</h2>
          <div className="policy-content">
            {legalNotices.map((legalNotice, idx) => {
              return (
                <Fragment key={`${legalNotice.id}-legalNotice`}>
                  <div className="policy-header">
                    {legalNotice.id}.{legalNotice.title}
                  </div>
                  <div>
                    {legalNotice.link ? (
                      <div>
                        <a className="cl-bahama-blue" href={legalNotice.link}>
                          {legalNotice.link}
                        </a>
                      </div>
                    ) : (
                      <div>{legalNotice.content}</div>
                    )}
                  </div>
                  <div className="ml-20">
                    {legalNotice.items &&
                      legalNotice.items.map((item, index) => {
                        return (
                          <Fragment key={`${item.id}-policy-item-${index}`}>
                            <div>{item.content}</div>
                          </Fragment>
                        )
                      })}
                  </div>

                  {idx + 1 < legalNotices?.length && (
                    <hr className="policy-hr" />
                  )}
                </Fragment>
              )
            })}
            <div className="mt-10">2023年3月10日施行</div>
          </div>
        </Wrapper>
        <div className="policy-footer" onClick={back}>
          <span className="mr-10">＜</span>戻る
        </div>
      </div>
    </Template>
  )
}
const Wrapper = styled.div`
  padding: 30px 20px 10px 20px;
  a {
    color: var(--color-bahama-blue);
  }
`
const Template = styled.div``

export default LegalNoticeContainer
