import React from 'react'
import styled from 'styled-components'
import LPHeader from 'src/components/layout/Header/LPHeader'

import { Button } from 'antd'
import { PATH } from 'src/pages/myPage'
import { useNavigate } from 'react-router-dom'

const ThankContainer: React.FC = () => {
  const navigate = useNavigate()

  const handleMyPage = () => {
    navigate(PATH.OTHER_MENU)
  }

  return (
    <Wrapper>
      <LPHeader />
      <div className="video-interview fixed-wrapper">
        <div className="page-title mb-50 text-center">
          ありがとうございました。
        </div>
        <div className="video-interview__content">
          医師より処方を致しますので、しばらくお待ち下さい。
          <br />
          処方後、メールにてご連絡致します。
        </div>
        <Button
          className="btn-default btn-gurkha fs-20 mt-10 mb-30 w-100 mt-60"
          onClick={handleMyPage}
        >
          マイページ
        </Button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .video-interview {
    padding: 0 20px;

    .page-title {
      font-size: 20px;
      font-weight: 500;
      margin-top: 80px;
    }

    &__content {
      font-size: 13px;
      background-color: #f1efe7;
      padding: 20px;
      margin: 20px 0 30px;
      font-weight: 300;

      p {
        margin-bottom: 0;
      }

      span {
        color: #998800;
        font-weight: 700;
      }
    }
  }
`

export default ThankContainer
