import React from 'react'
import { useNavigate } from 'react-router-dom'
import ButtonCustom from 'src/components/form/Button'
import DescriptionHint from 'src/components/layout/Content/DescriptionHint'

import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { PATH } from 'src/pages/myPage'
import { EMAIL_SUPPORT } from 'src/constants/env-constant'

const RegistrationCompleteContainer: React.FC = (props: any) => {
  const navigate = useNavigate()

  const handleMyPage = () => {
    navigate(PATH.OTHER_MENU)
  }
  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form not-padding-p">
        <StepsHeader
          step={10}
          stepActive={6}
          stepActiveName="会員登録情報入力"
          className="top-70"
        />
        <HeaderStyle>会員登録完了</HeaderStyle>
        <DescriptionHint
          listText={[
            'ご登録ありがとうございます。',
            '<br />',
            '医師よりご登録のメールアドレスに診察についてご連絡致します。',
            '<br />',
            'ご不明点がございましたら以下のメールアドレスまでお問い合わせください。',
            '<br />',
            EMAIL_SUPPORT,
          ]}
        />

        <ButtonCustom
          className="btn-default btn-gurkha mt-5 btn-submit"
          loading={false}
          onClick={handleMyPage}
          name="マイページへ"
        />

        {/* <LineBox /> */}
      </div>
    </>
  )
}

export default RegistrationCompleteContainer
