import { PATH as KARTE_PLAN } from 'src/pages/kartePlan'
import { PATH as REGULAR_PATH } from 'src/pages/regular'
import { PATH as PAYMENT_PATH } from 'src/pages/payment'
import { PATH as AUTOMATIC_PLAN_PATH } from 'src/pages/automaticPlan'

export const PAGE_CAN_NOT_BACK_WHEN_PAID = [
  PAYMENT_PATH.PAYMENT_INFO,
  AUTOMATIC_PLAN_PATH.PROPOSAL,
  AUTOMATIC_PLAN_PATH.QUESTIONS,
  AUTOMATIC_PLAN_PATH.PRECAUTION,
  AUTOMATIC_PLAN_PATH.PLAN_DETAIL,
  KARTE_PLAN.PRESCRIPTION_PROPOSAL,
  REGULAR_PATH.REGULAR_SUBSCRIPTION,
  AUTOMATIC_PLAN_PATH.SUGGEST_SUBSCRIPTION,
  AUTOMATIC_PLAN_PATH.MEDICAL_EXAMINATION,
]
