import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AddressByPostalCodeInterface } from 'src/interfaces/address'

type State = {
  loading: boolean
  entity: AddressByPostalCodeInterface | null
}

const initialState: State = {
  loading: false,
  entity: null,
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'address_delivery_by_postal_code',
  initialState,
  reducers: {
    updateEntity: (
      state,
      action: PayloadAction<AddressByPostalCodeInterface>,
    ) => {
      state.entity = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export { reducer }
export default actions
