import React from 'react'

import { PATH } from 'src/pages/listTreatmentCancel'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import AuthenticatedLayout from 'src/routes/components/AuthenticatedLayout'

const ListTreatmentCancelPage: IRouter[] = [
  {
    element: <AuthenticatedLayout />,
    children: [
      {
        path: PATH.LIST_TREATMENT_CANCEL,
        element: loadable(
          () => import('src/pages/listTreatmentCancel/ListTreatmentCancel'),
        ),
      },
    ],
  },
]

export default ListTreatmentCancelPage
