export const LP_HOME_PAGE_URL = process.env.REACT_APP_LP_HOME_PAGE_URL
export const SQ_APPLICATION_ID = process.env.REACT_APP_SQ_APPLICATION_ID || ''
export const SQ_LOCATION_ID = process.env.REACT_APP_SQ_LOCATION_ID
export const WEB_LINE_URL = process.env.REACT_APP_WEB_LINE_URL
export const WEB_FAQ_URL = process.env.REACT_APP_WEB_FAQ_URL
export const WEB_ONLINE_URL = process.env.REACT_APP_WEB_ONLINE_URL
export const EMAIL_SUPPORT = 'bioportplus@sheepmedical.com'
export const GTM_ID = process.env.REACT_APP_GTM_ID
export const LINE_CHANEL_ID = process.env.REACT_APP_LINE_CHANEL_ID
export const AUTH_LINE_URL = 'https://access.line.me/oauth2/v2.1/authorize'
export const APP_SQ_ENDPOINT_JS = process.env.REACT_APP_SQ_ENDPOINT_JS || ''
export const TIKTOK_ID = process.env.REACT_APP_TIKTOK_ID
export const GLOBAL_GTM_ID = process.env.REACT_APP_GLOBAL_GTM_ID
