export const USER_STATE = {
  PATIENT_REGISTERED: 'patient_registered',
  REGISTERED_INTERVIEW: 'registered_interview',
  CAUTION_OK: 'caution_ok',
  CAUTION_NG: 'caution_ng',
  NO_NEED_INTERVIEW: 'no_need_interview',
  SUGGESTED_SHAMPOO: 'suggested_shampoo',
  CHOICE_INTERVIEW_METHOD: 'choice_interview_method',
  CHAT_INTERVIEW_SCHEDULED: 'chat_interview_scheduled',
  VIDEO_INTERVIEW_SCHEDULED: 'video_interview_scheduled',
  RESERVATION_CONFIRMATION: 'reservation_confirmation',
  CONFIRMED_RESERVATION: 'confirmed_reservation',
  VIDEO_INTERVIEW_DATE: 'video_interview_date',
  CONFIRMING_PRESCRIPTION: 'confirming_prescription',
  UN_APPROVE_PRESCRIPTION: 'un_approve_prescription',
  NOT_PAID: 'not_paid',
  IDENTITY_VERIFICATION_NOT_REGISTERED: 'identity_verification_not_registered',
  IDENTITY_CHECK: 'identity_check',
  IDENTIFICATION_OK: 'identification_ok',
  IDENTIFICATION_NG: 'identification_ng',
  SHAMPOO_NOT_PAID: 'shampoo_not_paid',
  SHAMPOO_REGULAR_PLAN: 'shampoo_regular_plan',
  DELIVERY_ADDRESS_REGISTERED: 'delivery_address_registered',
  PRESCRIPTION_OK: 'prescription_ok',
  OTP_VERIFIED: 'otp_verified',
  PATIENT_PROVISIONALLY_REGISTERED: 'patient_provisionally_registered',
  CHOICE_INTERVIEW_METHOD_VERIFIED: 'choice_interview_method_verified',
  REQUESTING_SMS_VERIFICATION: 'requesting_sms_verification',
  SUBSCRIPTION_SUGGESTIONS: 'subscription_suggestions',
  SUCCESS_PAID: 'success_paid',
  SUBSCRIPTION_NOT_PAID: 'subscription_not_paid',
  SUBSCRIPTION_SUCCESS_PAID: 'subscription_success_paid',
  SUBSCRIPTION_SMS_VERIFICATION: 'subscription_sms_verification',
  SUBSCRIPTION_CONTRACT: 'subscription_contract',
  DOCTOR_PRESCRIPTION_OK: 'doctor_prescription_ok',
  KARTE_SMS_VERIFICATION: 'karte_sms_verification',
}
