import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Skeleton } from 'antd'
import AgoraRTC, {
  IAgoraRTCClient,
  ILocalAudioTrack,
  ILocalVideoTrack,
} from 'agora-rtc-sdk-ng'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/store'
import micOff from 'src/assets/icons/mic_off.svg'
import micOn from 'src/assets/icons/mic_on.svg'
import camOn from 'src/assets/icons/cam_on.svg'
import camOff from 'src/assets/icons/cam_off.svg'
import callEnd from 'src/assets/icons/call_end.svg'
import videoActions from 'src/store/domain/video/videoAppointment'
import { endVideoCall } from 'src/useCase/video'
import LocalStorage from 'src/utils/LocalStorage'
import { PATH as ERROR_PATH } from 'src/pages/error'

type IRtc = {
  localAudioTrack: ILocalAudioTrack | null
  localVideoTrack: ILocalVideoTrack | null
  client: IAgoraRTCClient | null
}
const Container: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const loading = useAppSelector(
    (state) => state.domain.video.videoAppointment.loading,
  )
  const agoraChannel = useAppSelector(
    (state) => state.domain.video.videoAppointment.agoraChannel,
  )
  const videoAppointmentEntity = useAppSelector(
    (state) => state.domain.video.videoAppointment.entity,
  )

  const [micState, setMicState] = useState<Boolean>(true)
  const [camState, setCamState] = useState<Boolean>(true)
  const [remoteAudioState, setRemoteAudioState] = useState<Boolean>(true)
  const [rtc] = useState<IRtc>({
    localAudioTrack: null,
    localVideoTrack: null,
    client: null,
  })

  if (!agoraChannel?.token) {
    const videoToken = LocalStorage.getVideoToken()
    if (videoToken) {
      navigate(`/video/incoming/${videoToken}`)
    } else {
      navigate(ERROR_PATH.STATUS_ERROR)
    }
  }

  useEffect(() => {
    initial().then()
  }, [])

  const initial = async () => {
    let options = {
      // Pass your App ID here.
      appId: agoraChannel?.app_id as string,
      // Set the channel name.
      channel: agoraChannel?.channel_uuid as string,
      // Pass your temp token here.
      token: agoraChannel?.token as string,
      // Set the user ID.
      uid: parseInt(agoraChannel?.uid as string),
    }

    // Check camera and microphone
    const msgCamAndMicError =
      'マイクとカメラへアクセスできません。\n' +
      '端末の設定を確認してください。'
    AgoraRTC.getCameras()
      .then()
      .catch(() => {
        videoActions.updateErrorMsg(msgCamAndMicError)
      })
    AgoraRTC.getMicrophones()
      .then()
      .catch(() => {
        videoActions.updateErrorMsg(msgCamAndMicError)
      })

    // Create an AgoraRTCClient object.
    rtc.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })

    // Listen for the "user-published" event, from which you can get an AgoraRTCRemoteUser object.
    rtc.client.on('user-published', async (user, mediaType) => {
      // Subscribe to the remote user when the SDK triggers the "user-published" event
      await rtc?.client?.subscribe(user, mediaType)

      // If the remote user publishes a video track.
      if (mediaType === 'video') {
        const rootRemoteContainer = document.getElementById('remote-user')
        // Get the RemoteVideoTrack object in the AgoraRTCRemoteUser object.
        const remoteVideoTrack = user.videoTrack
        // Dynamically create a container in the form of a DIV element for playing the remote video track.
        const remotePlayerContainer =
          document.getElementById('remote-user-video')
        if (!rootRemoteContainer || !remotePlayerContainer) return
        // Specify the ID of the DIV container. You can use the uid of the remote user.
        remotePlayerContainer.id = String(user.uid)
        remotePlayerContainer.style.width = '100%'
        remotePlayerContainer.style.height = '100%'
        rootRemoteContainer?.append(remotePlayerContainer)

        // Play the remote video track.
        // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
        remoteVideoTrack?.play(remotePlayerContainer)
      }

      // If the remote user publishes an audio track.
      if (mediaType === 'audio') {
        // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
        const remoteAudioTrack = user.audioTrack
        // Play the remote audio track. No need to pass any DOM element.
        remoteAudioTrack?.play()
        setRemoteAudioState(true)
      }

      // Listen for the "user-unpublished" event
      rtc?.client?.on('user-unpublished', (user, mediaType) => {
        if (mediaType === 'video') {
          const rootRemoteContainer = document.getElementById('remote-user')
          // @ts-ignore
          rootRemoteContainer?.removeChild(rootRemoteContainer?.firstChild)
          const div = document.createElement('div')
          div.id = 'remote-user-video'
          rootRemoteContainer?.appendChild(div)
        }

        if (mediaType === 'audio') {
          setRemoteAudioState(false)
        }
      })
    })

    // Join an RTC channel.
    await rtc.client.join(
      options.appId,
      options.channel,
      options.token,
      options.uid,
    )
    // Create a local audio track from the audio sampled by a microphone.
    rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
    // Create a local video track from the video captured by a camera.
    rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack()
    // Publish the local audio and video tracks to the RTC channel.
    await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack])
    // Dynamically create a container in the form of a DIV element for playing the local video track.

    const rootLocalContainer = document.getElementById('local-user')
    const localPlayerContainer = document.getElementById('local-user-video')
    if (!localPlayerContainer || !rootLocalContainer) return
    // Specify the ID of the DIV container. You can use the uid of the local user.
    localPlayerContainer.id = String(options.uid)
    localPlayerContainer.style.width = '100%'
    localPlayerContainer.style.height = '100%'
    rootLocalContainer.append(localPlayerContainer)

    // Play the local video track.
    // Pass the DIV container and the SDK dynamically creates a player in the container for playing the local video track.
    // @ts-ignore
    rtc.localVideoTrack.play(localPlayerContainer)
  }

  const toggleCamAction = async () => {
    const nextState = !camState
    setCamState(nextState)
    await rtc?.localVideoTrack?.setEnabled(nextState)
  }
  const toggleMicAction = async () => {
    const nextState = !micState
    setMicState(nextState)
    await rtc?.localAudioTrack?.setEnabled(nextState)
  }
  const endCall = () => {
    rtc?.client?.leave().then(() => {
      rtc?.localVideoTrack?.close()
      rtc?.localAudioTrack?.close()
      let data = {
        user_id: videoAppointmentEntity?.reservation?.user_id,
        reservation_id: videoAppointmentEntity?.reservation?.id,
      }
      dispatch(endVideoCall(data))
    })
  }
  return (
    <Wrapper>
      {loading ? (
        <Skeleton />
      ) : (
        <div id="aga-video-calling" className="fixed-wrapper">
          <div id="local-user">
            <div id="local-user-video"></div>
          </div>
          <div id="remote-user">
            <div id="remote-user-video"></div>
          </div>
          <div className="info remote-user-info">
            <p>{videoAppointmentEntity?.doctor.name}</p>
          </div>
          <div id="remote-audio">
            {!remoteAudioState && <img src={micOff} alt="" />}
          </div>
          <div id="action-control">
            <div className="camera" onClick={() => toggleCamAction()}>
              <img src={camState ? camOn : camOff} alt="" />
            </div>
            <div className="leave" onClick={() => endCall()}>
              <img src={callEnd} alt="" />
            </div>
            <div className="mic" onClick={() => toggleMicAction()}>
              <img src={micState ? micOn : micOff} alt="" />
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  #aga-video-calling {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    position: relative;

    #local-user {
      position: absolute;
      width: 30%;
      height: 20%;
      z-index: 2;
      right: 10px;
      bottom: 20%;
      background-color: #000;
    }

    @media (orientation: landscape) {
      #local-user {
        position: absolute;
        width: 30vh;
        height: 20vw;
        z-index: 2;
        right: 10px;
        bottom: 40%;
        background-color: #000;
      }
    }

    #remote-user {
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #f1efe7;
    }

    #action-control {
      position: absolute;
      z-index: 3;
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding: 0 10%;
      bottom: 7%;

      .camera,
      .mic,
      .leave {
        height: 80px;
        display: flex;
        align-items: center;
      }
    }

    #remote-audio {
      z-index: 4;
      position: absolute;
      top: 3%;
      left: 3%;
      width: 30px;
      height: 30px;
    }

    .remote-user-info {
      position: fixed;
      z-index: 10000;
      margin-left: 10px;
      margin-top: 20px;
      font-size: 15px;
    }
  }
`

export default Container
