import React, { useEffect } from 'react'
import { Form, Skeleton } from 'antd'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'src/store'
import { useLocation } from 'react-router-dom'

import ButtonCustom from 'src/components/form/Button'
import { getMenus } from 'src/useCase/question'

import {
  FormValues,
  useMedicalSpecialtyForm,
} from 'src/containers/MedicalSpecialty/components/useMedicalSpecialtyForm'
import SelectField from 'src/components/form/SelectField'
import LocalStorage from 'src/utils/LocalStorage'

const MedicalSpecialtyForm = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  const { form, onSubmit } = useMedicalSpecialtyForm()
  const { control, handleSubmit, formState, setValue } = form

  const menus = useAppSelector(
    (state) => state.domain.automaticPlan.menus.entity,
  )
  const loadingMenu =
    useAppSelector((state) => state.domain.automaticPlan.menus.loading) || false
  const menusOptions =
    Object.assign(menus).length > 0 &&
    Object.assign(menus).reduce((result, currentValue) => {
      result = [
        ...result,
        {
          id: currentValue.id,
          value: currentValue.id,
          label: currentValue.name,
        },
      ]
      return result
    }, [])

  const { menuId } = location.state || {}

  useEffect(() => {
    const isPaying = !!LocalStorage.getLocalStorage('is_paying')

    if (isPaying) {
      LocalStorage.removeLocalStorage('is_paying')
    }

    if (menuId) {
      setValue('menu_id', menuId)
    }
  }, [menuId, setValue])

  useEffect(() => {
    const params = {}
    dispatch(getMenus(params))
  }, [])

  return (
    <Wrapper>
      {loadingMenu ? (
        <Skeleton />
      ) : (
        <Form onFinish={handleSubmit(onSubmit)}>
          <SelectField<FormValues>
            label="診療科目"
            selectProps={{
              options: menusOptions,
              loading: false,
              allowClear: false,
              placeholder: '選択してください',
            }}
            controller={{ name: 'menu_id', control }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          />
          <ButtonCustom
            className="btn-default btn-chestnut-rose br-60 mt-30"
            loading={formState.isSubmitting}
            htmlType="submit"
            name="問診へ進む"
            showIconRight
          />
        </Form>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--color-pampas);
    border: 1px solid var(--color-pampas);
    border-radius: 10px;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
`

export default MedicalSpecialtyForm
