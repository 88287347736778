import React from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { Modal } from 'antd'
import { ModalStyle, TopIcon } from './ModalStyle'
import agaPopupIcon from '../../assets/icons/sheep.jpeg'

const ConfirmPlanModal = (props) => {
  const handleClose = () => {
    props.onCancel && props.onCancel()
  }

  const portalDiv = document.getElementById('root')!

  return (
    props.isOpen &&
    document.getElementById('root') &&
    createPortal(
      <Modal
        open={props.isOpen}
        centered
        onOk={handleClose}
        onCancel={handleClose}
        closable={false}
        footer={[
          <div key="back" className="text-center">
            <ButtonOk onClick={handleClose} className="btn-ok">
              戻る
            </ButtonOk>
          </div>,
        ]}
        className="basic-modal"
      >
        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-center">
            <TopIcon>
              <img className="img" src={agaPopupIcon} alt="" />
            </TopIcon>
          </div>
        </div>
        <ModalStyle>{props.children}</ModalStyle>
      </Modal>,
      portalDiv,
    )
  )
}

const ButtonOk = styled.div`
  color: #000;
  font-weight: 700;
  font-size: 18px;
  border: unset;
  background-color: unset;
  cursor: pointer;
`

export default ConfirmPlanModal
