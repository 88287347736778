import { createAsyncThunk } from '@reduxjs/toolkit'
import precautionsRequest from 'src/api/requests/precaution'
import precautionsActions from 'src/store/domain/automaticPlan/precaution'
import { LocalStorage } from 'src/utils/LocalStorage'
import history from 'src/libs/history'
import { PATH as PATH_VIDEO } from 'src/pages/video'
import { get } from 'lodash'
import { USER_STATE } from 'src/enum/userStatus'

export interface PrecautionRequestInterface {
  menu_id: string
}

export const getPrecautionsByMenuId = createAsyncThunk<
  any,
  PrecautionRequestInterface
>('get_precautions_by_menu_id', async ({ menu_id }, { dispatch }) => {
  try {
    dispatch(precautionsActions.updateLoading(true))
    const res = await precautionsRequest.getPrecautionsByMenuId(menu_id)
    if (res.data.data) {
      dispatch(precautionsActions.updateEntity(res.data.data))
    }
    dispatch(precautionsActions.updateLoading(false))
  } catch (error) {
    dispatch(precautionsActions.updateLoading(false))
  }
})

export const checkPrecautionInfo = createAsyncThunk<any>(
  'check_precaution',
  async (params, { dispatch }) => {
    try {
      dispatch(precautionsActions.updateLoading(true))
      const userTreatmentId = LocalStorage.getUserTreatmentId()
      const res = await precautionsRequest.checkPrecaution({
        user_treatment_id: Number(userTreatmentId),
      })
      const state = get(res, 'data.state')

      if (LocalStorage.precaution) {
        LocalStorage.removePrecaution()
      }

      if (state === USER_STATE.VIDEO_INTERVIEW_SCHEDULED) {
        history.push(PATH_VIDEO.VIDEO_INTERVIEW)
      }

      dispatch(precautionsActions.updateLoading(false))
    } catch (error) {
      dispatch(precautionsActions.updateLoading(false))
    }
  },
)
