import styled from 'styled-components'

export const Template = styled.div`
  height: 100vh;

  .flow {
    margin-top: 30px;
    padding: 15px;

    &-header {
      .btn-primary {
        img {
          position: absolute;
          right: 25px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }

    .ant-carousel {
      .slick-dots {
        &-bottom {
          bottom: 85px;
        }

        li {
          width: 8px;
          height: 8px;
          border-radius: 50px;
          background: #e4e4e0;
          button {
            background: #e4e4e0;
          }
        }

        .slick-active {
          background: #cfc392;
          width: 8px;
          height: 8px;
          border-radius: 50px;
          button {
            width: 8px;
            height: 8px;
            background: #cfc392;
            border-radius: 50px;
          }
        }
      }

      .slick-slide {
        img {
          border-radius: 50%;
        }
      }
    }
  }
`
