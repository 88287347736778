import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import StepsHeader from 'src/components/layout/Header/StepHeader'
import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import DescriptionHint from 'src/components/layout/Content/DescriptionHint'
import ButtonCustom from 'src/components/form/Button'
import { PATH } from 'src/pages/automaticPlan'
import { PATH as MY_PAGE_PATH } from 'src/pages/myPage'
import LPHeader from 'src/components/layout/Header/LPHeader'
import { PLAN_TYPE } from 'src/constants/app'
import ShampooHeader from 'src/components/layout/Header/ShampooHeader'
import { useAppDispatch, useAppSelector } from 'src/store'
import { getUserPlanType } from 'src/utils/helper'
import { getInfo } from 'src/useCase/user'
import { EMAIL_SUPPORT } from 'src/constants/env-constant'
import LocalStorage from 'src/utils/LocalStorage'
import { PLAN_SHAMPOO_ADDITIONAL_ID } from 'src/constants/order'
import HeaderUnderlined from 'src/components/layout/Header/HeaderUnderlined'
import { USER_STATE } from 'src/enum/userStatus'

const PaymentSuccessContainer: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.ui.app.authInfo)
  const planId = _.get(user, 'orderPaymented[0].plan_id', 0) as number
  const planType =
    LocalStorage.planType || getUserPlanType(user?.state_transitions || [])

  const isExamOrPrescription =
    planType === PLAN_TYPE.EXAMINATION || planType === PLAN_TYPE.PRESCRIPTION
  const isSubscription = planType === PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION

  const next = () => {
    const userState = user?.new_state
    // user registered card
    if (
      _.includes(
        [USER_STATE.IDENTIFICATION_OK, USER_STATE.IDENTITY_CHECK],
        userState,
      )
    ) {
      return navigate(MY_PAGE_PATH.OTHER_MENU)
    }

    if (planId == PLAN_SHAMPOO_ADDITIONAL_ID) {
      navigate(MY_PAGE_PATH.OTHER_MENU)
    } else {
      if (isExamOrPrescription) {
        navigate(PATH.IDENTIFICATION)
      }
      if (isSubscription) {
        navigate(MY_PAGE_PATH.OTHER_MENU)
      }
    }
  }

  useEffect(() => {
    if (user?.state_transitions !== undefined) {
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay,orderPaymented,userTreatments,userTreatments.userTreatmentTransitions',
        }),
      )
    }
  }, [])

  return (
    <Wrapper>
      <LPHeader />
      <div
        className={`w-full justify-center mx-auto register-form ${
          isExamOrPrescription ? 'layout-webview-step' : 'p-20'
        }`}
      >
        {planId == PLAN_SHAMPOO_ADDITIONAL_ID ? (
          <>
            <ShampooHeader name="決済完了" className="top-70" />
            <HeaderStyle>お支払い完了</HeaderStyle>
            {user?.line_id ? (
              <DescriptionHint
                listText={[
                  'ご注文ありがとうございます。',
                  '<br />',
                  '配送準備が完了次第、LINEメッセージにてご連絡いたします。',
                  '<br />',
                  'ご不明点がございましたらLINE公式アカウントのトークにてお問い合わせください。',
                  'または以下のメールアドレスまでお問い合わせください。',
                  '<br />',
                  EMAIL_SUPPORT,
                ]}
              />
            ) : (
              <DescriptionHint
                listText={[
                  'ご注文ありがとうございます。',
                  '<br />',
                  '配送準備が完了次第ご連絡いたします。',
                  '<br />',
                  'ご不明点がございましたら以下のメールアドレスまでお問い合わせください。',
                  '<br />',
                  EMAIL_SUPPORT,
                ]}
              />
            )}
          </>
        ) : (
          <>
            {isExamOrPrescription && (
              <>
                <StepsHeader
                  step={10}
                  stepActive={6}
                  stepActiveName={'基本情報入力'}
                  className="top-70"
                />
                <HeaderStyle>
                  <p className="mb-2">決済・会員登録完了</p>
                  <p className="text-16">
                    ※最後のステップがまだ残っています本人確認書類の提出へお進みください
                  </p>
                </HeaderStyle>
                <DescriptionHint
                  listText={[
                    'ご利用ありがとうございます。',
                    '<br />',
                    '続いて本人確認書類の提出を行ってください。',
                    '医師による、本人確認書類の確認後に配送手続きとなります。',
                  ]}
                />
              </>
            )}
            {isSubscription && (
              <>
                <HeaderUnderlined name="決済完了" />
                <HeaderStyle>お支払い完了</HeaderStyle>
                {/* {user?.line_id ? (
                  <>
                    <DescriptionHint
                      listText={[
                        'ご注文ありがとうございます。',
                        '<br />',
                        '配送準備が完了次第ご連絡いたします。',
                        '<br />',
                        'ご不明点がございましたら以下のメールアドレスまでお問い合わせください。',
                        'お問合せ時は、ご登録頂きましたお名前をご記入ください。',
                        'お問い合わせは随時返信を行なっておりますが、時間を要する場合もございますのでご了承ください。',
                        '<br />',
                        EMAIL_SUPPORT,
                      ]}
                    />
                  </>
                ) : (
                  <DescriptionHint
                    listText={[
                      'ご注文ありがとうございます。',
                      '<br />',
                      '配送準備が完了次第ご連絡いたします。',
                      '<br />',
                      'ご不明点がございましたら画面下のLINE ID連携よりLINE IDと連携後、LINE公式アカウントのトークにてお問い合わせください。',
                      'または以下のメールアドレスまでお問い合わせください。',
                      '<br />',
                      EMAIL_SUPPORT,
                    ]}
                  />
                )} */}
                <DescriptionHint
                  listText={[
                    'ご注文ありがとうございます。',
                    '<br />',
                    '配送準備が完了次第ご連絡いたします。',
                    '<br />',
                    'ご不明点がございましたら以下のメールアドレスまでお問い合わせください。',
                    '<br />',
                    EMAIL_SUPPORT,
                  ]}
                />
              </>
            )}
          </>
        )}

        <ButtonCustom
          loading={false}
          className={`btn-default mt-5 ${
            isSubscription ? 'btn-gurkha' : 'btn-chestnut-rose'
          }`}
          name={
            isSubscription
              ? 'マイページへ戻る'
              : planId == PLAN_SHAMPOO_ADDITIONAL_ID || !isExamOrPrescription
              ? 'マイページへ戻る'
              : '本人確認書類提出へ進む'
          }
          onClick={next}
          showIconRight
        />

        {/* {planId == PLAN_SHAMPOO_ADDITIONAL_ID || !isExamOrPrescription ? (
          <LineBox />
        ) : (
          ''
        )} */}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .text-16 {
    font-size: 16px;
    line-height: 1.3;
  }
`

export default PaymentSuccessContainer
