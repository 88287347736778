import React, { Fragment, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { polices } from './constant'

const PolicyContainer: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const back = () => {
    navigate(-1)
  }
  return (
    <Template>
      <div className="policy">
        <Wrapper>
          <h2 className="policy-top">プライバシーポリシー</h2>
          <div className="mb-20">
            本プライバシーポリシーは、SheepMedical株式会社（以下「当社」といいます。）が提供するオンライン診療及び処方システムBIOPORT+におけるサービス（以下「本件サービス」といいます。）に関して、本件サービスの利用者及び利用者以外の者（以下「お客様」といいます。）から取得した個人情報の収集、利用、開示、管理及び保護の取扱いについて説明するものです。「個人情報」とは、特定されたあるいは特定可能な自然人を識別し得る情報を指します。本プライバシーポリシーは、当社が保有する、又は当社の管理下にある個人情報に適用されます。これには、当社の目的のために個人情報を収集、使用、開示又は処理するよう当社が委託した組織が保有する個人情報も含まれます。
          </div>
          <div className="policy-content">
            {polices.map((policy) => {
              return (
                <Fragment key={`${policy.id}-policy`}>
                  <div className="policy-header">
                    {policy.id}.{policy.title}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: policy.content || '' }}
                  ></div>
                  {policy.content_2 && <div>{policy.content_2}</div>}
                  {policy.content_3 && <div>{policy.content_3}</div>}
                  <div className="ml-20">
                    {policy.items &&
                      policy.items.map((item, index) => {
                        return (
                          <Fragment key={`${item.id}-policy-item-${index}`}>
                            <div>
                              {item.count} {item.content}{' '}
                              {item.content_2 && item.link ? (
                                <div>
                                  <a
                                    className="cl-bahama-blue"
                                    href={item.link}
                                  >
                                    {item.content_2}
                                  </a>
                                </div>
                              ) : (
                                <div>{item.content_2}</div>
                              )}
                            </div>
                          </Fragment>
                        )
                      })}
                  </div>
                  <div>
                    {policy.paragraphs &&
                      policy.paragraphs.map((item) => {
                        return (
                          <Fragment key={`${item.id}-paragraphs`}>
                            <div>
                              <div>
                                ({item.id}) {item.title}
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.title_2 || '',
                                }}
                              ></div>
                              <div className="font-weight-bold">
                                {item.title_3}
                              </div>
                              <a className="cl-bahama-blue" href={item.title_4}>
                                {item.title_4}
                              </a>
                            </div>
                            <div className="ml-20">
                              {item.items &&
                                item.items.map((para) => {
                                  return (
                                    <Fragment
                                      key={`${para.id}-paragraphs-para`}
                                    >
                                      <div>
                                        {para.count} {para.content}{' '}
                                        {para.content_2 && (
                                          <div>{para.content_2}</div>
                                        )}
                                      </div>
                                    </Fragment>
                                  )
                                })}
                            </div>
                          </Fragment>
                        )
                      })}
                  </div>
                  <hr className="policy-hr" />
                </Fragment>
              )
            })}
            <div>
              <div>SheepMedical株式会社 </div>
              <div>bioportplus@sheepmedical.com</div>
              <div className="text-right">2023年3月10日施行</div>
            </div>
          </div>
        </Wrapper>
        <div className="policy-footer" onClick={back}>
          <span className="mr-10">＜</span>戻る
        </div>
      </div>
    </Template>
  )
}
const Wrapper = styled.div`
  padding: 30px 20px 10px 20px;
  a {
    color: var(--color-bahama-blue);
  }
`
const Template = styled.div``

export default PolicyContainer
