import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from 'src/store'
import { createUserTreatment } from 'src/useCase/userTreatment'

export type FormValues = {
  menu_id: string | number | null
}

export const useMedicalSpecialtyForm = () => {
  const dispatch = useAppDispatch()

  const defaultValues: FormValues = {
    menu_id: null,
  }

  const validationSchema = Yup.object().shape({
    menu_id: Yup.string().required('入力してください。').nullable(),
  })

  const onSubmit = async (data: FormValues) => {
    await dispatch(createUserTreatment({ data: data, form: form }))
  }

  const form = useForm({
    defaultValues,
    shouldUnregister: false,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}
