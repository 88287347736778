import React from 'react'
import { UserInterface } from 'src/interfaces/user'

type Props = {
  user: UserInterface
}

const UserInfo = ({ user }: Props) => {
  return (
    <div className="section">
      <div className="sec-title">配送先情報</div>
      <div className="sec-body">
        <div className="d-flex justify-content-between content">
          <span>氏名</span>
          <strong>
            {user ? `${user.family_name} ${user.given_name}` : ''}
          </strong>
        </div>
        <div className="d-flex justify-content-between content">
          <span>郵便番号</span>
          <strong>{user ? user.postal_number : ''}</strong>
        </div>
        <div className="d-flex justify-content-between content">
          <span>都道府県</span>
          <strong>{user ? user.prefecture_id : ''}</strong>
        </div>
        <div className="d-flex justify-content-between content">
          <span>市区町村・番地</span>
          <strong>{user ? user.city_id : ''}</strong>
        </div>
        <div className="d-flex justify-content-between content">
          <span>番地以降</span>
          <strong>{user ? user.address : ''}</strong>
        </div>
        <div className="d-flex justify-content-between content">
          <span>建物名・部屋番号</span>
          <strong>{user ? user.building_name : ''}</strong>
        </div>
        <div className="d-flex justify-content-between content">
          <span>電話番号</span>
          <strong>{user ? user.phone : ''}</strong>
        </div>
        <div className="d-flex justify-content-between content">
          <span>メールアドレス</span>
          <strong>{user ? user.email : ''}</strong>
        </div>
        {/*<button className="plan-detail__submit">*/}
        {/*  配送先を変更する*/}
        {/*</button>*/}
      </div>
    </div>
  )
}

export default UserInfo
