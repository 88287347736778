import React from 'react'
import Modal from './BasicModalConfirm'
import agaPopupIcon from 'src/assets/icons/sheep.jpeg'
import { TopIcon } from './ModalStyle'

type Props = {
  isOpen: boolean
  onSubmit: () => void
  onCancel: () => void
  doctorRequestDate?: string
  menuName: string
}

const VideoCallModal: React.FC<Props> = ({
  isOpen,
  onSubmit,
  onCancel,
  doctorRequestDate,
  menuName,
}) => {
  const handleCancel = () => {
    onCancel()
  }

  const handleSubmit = () => {
    onSubmit()
  }

  const formatDateAndTime = (dateString?: string) => {
    if (!dateString) return { date: '', time: '' }

    const dateObj = new Date(dateString)
    const year = dateObj.getFullYear()
    const month = String(dateObj.getMonth() + 1).padStart(2, '0')
    const day = String(dateObj.getDate()).padStart(2, '0')
    const hours = String(dateObj.getHours()).padStart(2, '0')
    const minutes = String(dateObj.getMinutes()).padStart(2, '0')

    return {
      date: `${year}年${month}月${day}日`,
      time: `${hours}時${minutes}分`,
    }
  }

  const { date, time } = formatDateAndTime(doctorRequestDate)

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCancel}
      onSubmit={handleSubmit}
      labelSubmit="同意する"
      labelCancel="同意しない"
    >
      <div className="d-flex justify-content-center">
        <TopIcon>
          <img className="img" src={agaPopupIcon} alt="Video Call Icon" />
        </TopIcon>
      </div>
      <div className="modal-content styleCustomModal">
        <div className="modal-body">
          <p>
            ドクターから下記の日程よりビデオ診察のお願いがありました。参加しますか？
          </p>
          <div className="d-flex justify-content-center mt-2 gap-2">
            <span className="col-5">診療項目:&nbsp;&nbsp;</span>
            <span className="col-7">{menuName}</span>
          </div>
          <div className="d-flex justify-content-center mt-2 gap-2">
            <span className="col-5">日付:&nbsp;&nbsp;</span>
            <span className="col-7">{date}</span>
          </div>
          <div className="d-flex justify-content-center mt-2 gap-2">
            <span className="col-5">時間:&nbsp;&nbsp;</span>
            <span className="col-7">{time}</span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default VideoCallModal
