import React from 'react'
import Modal from './BasicModal'

import agaPopupIcon from 'src/assets/icons/sheep.jpeg'
import { TopIcon } from './ModalStyle'

type Props = {
  isOpen: boolean
  title?: string
  onSubmit: (submit: boolean) => void
  labelSubmit?: string
  labelCancel?: string
  classSubmit?: string
}

const BirthDateAGenderModal: React.FC<Props> = (props) => {
  const handleCancel = () => {
    props.onSubmit(false)
  }

  return (
    <Modal isOpen={props.isOpen} onClose={handleCancel}>
      <div className="d-flex justify-content-center">
        <TopIcon>
          <img className="img" src={agaPopupIcon} alt="" />
        </TopIcon>
      </div>
      <div className={`modal-content not-padding-p`}>
        <div className="modal-header fs-18 mt-2 mb-2">申し訳ありません。</div>
        <div>
          <p>本サービスは男性のみご利用いただけます。</p>
          <p>
            <b>今後、女性向けサービスの開始を予定</b>
            しておりますので今しばらくおまちください。
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default BirthDateAGenderModal
