import { combineReducers } from '@reduxjs/toolkit'

import aga from './aga'
import automaticPlan from './automaticPlan'
import video from './video'
import plans from './plan'
import doctor from './doctor'
import payment from './payment'
import chat from './chat'
import myPage from './myPage'
import user from './user'
import addressByPostalCode from './address'
import feedbacks from './feedback'
import notification from './notification'
import additionalShampoo from './additionalShampoo'
import clinic from './clinic'
import orders from './orders'

export default combineReducers({
  aga,
  automaticPlan,
  plans,
  video,
  doctor,
  payment,
  chat,
  myPage,
  user,
  addressByPostalCode,
  feedbacks,
  notification,
  additionalShampoo,
  clinic,
  orders,
})
