import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Badge } from 'antd'
import { useNavigate } from 'react-router-dom'
import lodashSome from 'lodash/some'
import lodashGet from 'lodash/get'

import newRight from 'src/assets/icons/new-right.svg'
import LPHeader from 'src/components/layout/Header/LPHeader'
import { useAppSelector } from 'src/store'
import registeredPlanIcon from 'src/assets/icons/registered_plan_icon.svg'
import unregisteredPlanIcon from 'src/assets/icons/unregistered_plan_icon.svg'
import iconChat from 'src/assets/icons/doctor-chat.svg'
import iconUser from 'src/assets/icons/user.svg'
import { PATH as PATH_USER_DETAIL } from 'src/pages/myPage/UserDetail'
import { PATH as PATH_LIST_NOTIFICATION } from 'src/pages/notification/ListNotification'
import iconNotification from 'src/assets/icons/notification.svg'
import { USER_STATE } from 'src/enum/userStatus'
import CommonModal from 'src/components/Modal/CommonModal'
import contractHistoryIcon from 'src/assets/icons/contract_history_icon.svg'
import { getMyPageCount } from 'src/useCase/myPage'
import { PATH as PATH_MY_PAGE } from 'src/pages/myPage'
import { USER_TREATMENT_STATE } from 'src/enum/userTreatmentState'
import iconTreatmentDark from 'src/assets/icons/treatment-dark.svg'
import { PATH as PATH_AUTH } from 'src/pages/auth'
import LocalStorage from 'src/utils/LocalStorage'

export type MyPageMenuType = {
  name: string
  icon: string
  link: string
  count?: number
  isOpenModal?: boolean
  isHidden?: boolean
}

const Container: React.FC = () => {
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [menus, setMenus] = useState<MyPageMenuType[]>([])
  const user = useAppSelector((state) => state.ui.app.authInfo)
  const countUnreadMenu = useAppSelector(
    (state) => state.domain.notification.countUnreadNoticeReducer.entity,
  )

  const makeMenus = async () => {
    const notState = [
      USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_OK,
      USER_TREATMENT_STATE.SUBSCRIPTION_CONTRACT,
      USER_TREATMENT_STATE.CANCELED,
    ]
    const params = {
      status: 'cancelled_or_complete',
      paid_orders: true,
      user_treatment_not_states: ['all', notState.join(',')],
    }
    const counts = await getMyPageCount(params)

    const userRegisteredInfo = lodashSome(
      user?.state_transitions,
      (data) => data.state === USER_STATE.IDENTITY_VERIFICATION_NOT_REGISTERED,
    )

    setMenus([
      {
        name: '処方・購入手続きの再開',
        icon: unregisteredPlanIcon,
        link: PATH_MY_PAGE.TREATMENT_PATH,
        isHidden: lodashGet(counts, 'user_treatment_not_states.[1]') == 0,
      },
      {
        name: 'ご契約中のプラン',
        icon: registeredPlanIcon,
        link: PATH_MY_PAGE.PURCHASE_HISTORY,
        isHidden: counts?.paid_orders == 0 || !userRegisteredInfo,
      },
      {
        name: '契約履歴',
        icon: contractHistoryIcon,
        link: `${PATH_MY_PAGE.PURCHASE_HISTORY}?status=cancelled_or_complete`,
        isHidden: Number(counts?.cancelled_or_complete) == 0,
      },
      {
        name: 'ドクターチャット',
        icon: iconChat,
        link: `${PATH_MY_PAGE.TREATMENT_PATH}?from=chat`,
        isHidden: lodashGet(counts, 'user_treatment_not_states.[0]') == 0,
      },
      {
        name: '会員情報の確認・変更',
        icon: iconUser,
        link: PATH_USER_DETAIL,
      },
      {
        name: '新規プランの購入',
        icon: iconTreatmentDark,
        link: PATH_AUTH.MEDICAL_SPECIALTY,
      },
      {
        name: 'お知らせ',
        icon: iconNotification,
        link: PATH_LIST_NOTIFICATION,
        count: countUnreadMenu,
      },
    ])
  }

  useEffect(() => {
    const isPaying = !!LocalStorage.getLocalStorage('is_paying')

    if (isPaying || LocalStorage.getUserTreatmentId()) {
      LocalStorage.removeLocalStorage('is_paying')
      LocalStorage.removeUserTreatmentId()
    }

    if (user) {
      makeMenus()
    }
  }, [user])

  useEffect(() => {
    const newMenus = [...menus]

    const noticeMenu = newMenus.find(
      (Item) => Item.link === PATH_LIST_NOTIFICATION,
    )

    if (noticeMenu) {
      noticeMenu.count = countUnreadMenu
      setMenus(newMenus)
    }
  }, [countUnreadMenu])

  return (
    <Wrapper>
      <LPHeader />
      <div className="other-menu">
        <div className="w-full justify-center mt-20 mx-auto layout-webview">
          {menus.map((item: MyPageMenuType, index) => {
            return (
              <div key={index}>
                {item.isHidden ? (
                  ''
                ) : (
                  <div
                    onClick={() => {
                      if (
                        item.isOpenModal &&
                        ((user?.state === USER_STATE.CHOICE_INTERVIEW_METHOD &&
                          user?.hasPassword) ||
                          user?.state === USER_STATE.IDENTIFICATION_OK)
                      ) {
                        setOpenModal(true)
                      } else {
                        navigate(item.link)
                      }
                    }}
                    className="menu-item"
                  >
                    <span className="d-flex justify-content-between pb-10">
                      <div className="flex-auto flex fw-bold">
                        <Badge className="mr-15" count={item.count || 0}>
                          <img
                            style={{
                              width: '32px',
                              height: '32px',
                            }}
                            src={item.icon}
                          />
                        </Badge>
                        <span className="menu-name cl-black">{item.name}</span>
                      </div>
                      <div className="d-flex justify-content-end align-items-center">
                        <img
                          style={{ width: '15px', height: '20px' }}
                          src={newRight}
                        />
                      </div>
                    </span>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <CommonModal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <div className="p-15 text-center mt-20">
            {user?.state === USER_STATE.IDENTIFICATION_OK ? (
              <>
                <b className="fs-20">医師の最終処方確認中です</b>
                <br />
                <br />
                <span className="text-center pt-25">
                  本人確認書類が完了しましたので、医師によって処方の最終確認を行っております。
                </span>
                <br />
                <span className="text-center">しばらくお待ち下さい。</span>
              </>
            ) : (
              <>
                <b className="fs-20">医師の診察が必要です</b>
                <br />
                <br />
                <span className="text-center">
                  問診の結果、お客様につきましては医師の診察が必要となります
                </span>
                <br />
                <span className="text-center">
                  後ほど医師からご連絡をお待ちください。
                </span>
              </>
            )}
          </div>
        </CommonModal>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #f1efe7;
  min-height: 100vh;
  .other-menu {
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: #000000;
      margin-top: 10px;
    }
    .menu-name {
      font-size: 15px;
      line-height: 32px;
    }
  }

  .menu-item {
    color: #000000;
    margin-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
    line-height: 40px;
  }

  .visibility-hidden {
    visibility: hidden;
  }
`

export default Container
